import { uid } from "uid";
import { RequiredState } from "../general.types";
import { Country, County } from "../officialHoliday/OfficialHoliday.types";
import { DayOfWeek } from "../time/Timetracking.types";
import { UserRight } from "../userRole/UserRole.types";
import { Language } from "../Language/Language.types";
import { Period } from "../invoice/Invoice.types";

/**
 * interface for all kinds of users with basic information that are necessary
 */
export interface User {
  id: string;
  password?: string;
  createDate: Date;
  createdBy: string;
  lastUpdated?: Date;
  updatedBy?: string;
  gender: Gender;
  title: string;
  firstName: string;
  lastName: string;
  contact: Contact;
  username?: string;
}

/**
 * interface for users that are employed by Schambeck
 * including all neccessary informations and the user rights
 */
export interface SchambeckUser extends User {
  privateInformation: PrivateInformation;
  personalNumber: string;
  active: boolean;
  supervisorId: string;
  officeId: string;
  deputyId: string;
  contractInformation: ContractInformation;
  right: UserRight;
  workQualifications: string[];
  customerTimes: Map<string, number>;
  userLanguages: string[];
  annotation?: string;
  businessAreaIds: string[];
  userFunctionId: string;
  availableOffices: string[];
}

/**
 * interface for users that are employed by customers and have access to the system
 */
export interface CustomerUser extends User {
  customerId: string;
  disabled: boolean;
  departmentId?: string;
  departmentDetail?: string;
  locations: string[];
  defaultInvoiceContact?: boolean;
  defaultDunningContact?: boolean;
}

/**
 * A user that is linked to a Pdl
 */
export interface PdlUser extends User {
  pdlId: string;
  pdlContact: boolean;
  mainOffice?: string;
  departmentId?: string;
}

/**
 * This is the so called "Mitarbeiterfunktion" in which the differend areas of
 * user function are defined
 */
export interface UserFunction {
  id: string;
  createdBy: string;
  createDate: Date;
  updatedBy?: string;
  lastUpdate?: Date;
  disabled: boolean;
  title: string;
  names: Map<Language, string>;
}

/**
 * enum to choose gender for a user
 */
export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER = "OTHER",
}
/**
 * Interface for ContactData of an user
 */
export interface Contact {
  id: string;
  phone: string;
  mobile: string;
  mail: string;
  fax: string;
  street: string;
  streetNumber: string;
  zip: string;
  city: string;
  country: Country;
  county: County;
  website: string;

  phonePrivate: string;
  mobilePrivate: string;
  mailPrivate: string;
  streetPrivate: string;
  streetNumberPrivate: string;
  zipPrivate: string;
  cityPrivate: string;
  websitePrivate: string;
  countryPrivate: Country;
  countyPrivate: County;
}
/**
 * private Information of a user that shouldn`t be public
 */
export interface PrivateInformation {
  id?: string;
  birthDate: Date;
  birthCity: string;
  nationality: string;
  residencePermitEndDate: Date;
  workPermitEndDate: Date;
  bankAccount: BankAccount;
  healthInsurance: string;
  passportNumber: string;
  taxId: string;
  children: boolean;
  religion: string;
  taxClass: TaxClass;
  driverLicenseCopyState: RequiredState;
  familyState: FamilyState;
  /**
   * @deprecated this will be removed and used in `driverLicenseTypes`
   */
  driverLicenseType: DriverLicenseType;
  driverLicenseTypes?: DriverLicenseType[];
  educationLevel: EducationLevel;
  educationQualification: EducationQualification;
  disabledLevel?: string;
  severlyDisabled: boolean;
  equalized: boolean;
}

/**
 * Information object that holds information for the contract of an employee
 */
export interface ContractInformation {
  id: string;
  createdBy: string;
  createDate: Date;
  description: string;
  position: Position;
  entryDate: Date;
  leavingDate?: Date;
  reentryDate?: Date;
  briefingDate?: Date;
  officeId: string;
  currentOfficeId?: string;
  qualifications: Qualification[];
  weeklyWorkingTime: number;
  workScheduleDistribution: WorkScheduleDistribution;
  workSchedules: WorkSchedule[];
  employmentType: EmploymentType;
  propationEndDate?: Date;
  propationFinished: boolean;
  mainEmployee: boolean;
  salary: number;
  fixedSalary: number;
  holidayAmount: number;
  secondaryActivityAllowed: boolean;
  capitalAccumulationAllowed: boolean;
  carId: string;
  bycicleId: string;
  yearlyTimes?: Map<number, YearlyTimeOverview>;
  vwl?: string;
  azk?: string;
  yearlyStackerIntro?: Date;
  eyeTestDate?: Date;
  eyeTestDeadline?: number;
  employmentHistory?: Map<Date, EmploymentChange>;
  wage?: string;
}

/**
 * This constant defines the type of the employment of a worker
 */
export enum EmploymentType {
  /**
   * meaning Festangestellt
   */
  PERMANENT = "PERMANENT",
  FREELANCE = "FREELANCE",
  /**
   * meaning Personaldienstleister
   */
  PDL = "PDL",
}

/**
 * Used to monitor the change in employment state of schambeckusers
 */
export enum EmploymentChange {
  ENTERING = "ENTERING",
  LEAVING = "LEAVING",
}

/**
 * interface for a YearlyTimeOverview
 * that holds the workingTime summarys for the Soll, Ist and sick totals
 */
export interface YearlyTimeOverview {
  totalIst: number;
  totalSoll: number;
  totalSick: number;
}
/**
 * Helper to generate a new ContractInformation with the option
 * to override single fields
 *
 * @param creatorId The if of the currently logged in user
 * @param override Optional fields to override in the new objekt
 * @returns A new ContractInformation instance
 */
export const generateEmptyContractInformation = (
  creatorId: string,
  override?: Partial<ContractInformation>
): ContractInformation => ({
  id: uid(),
  createdBy: creatorId,
  createDate: new Date(),
  position: Position.QTEAM,
  entryDate: new Date(),
  officeId: "",
  qualifications: [],
  weeklyWorkingTime: 0,
  workScheduleDistribution: WorkScheduleDistribution.WEEKLY,
  workSchedules: [],
  propationFinished: false,
  mainEmployee: false,
  salary: 0,
  fixedSalary: 0,
  holidayAmount: 0,
  secondaryActivityAllowed: false,
  capitalAccumulationAllowed: false,
  carId: "",
  bycicleId: "",
  employmentType: EmploymentType.PERMANENT,
  description: "",

  ...override,
});

/**
 * This field states in which way the user working time is entered and
 * distributed over the week
 */
export enum WorkScheduleDistribution {
  WEEKLY = "WEEKLY",
  DAILY = "DAILY",
  PRECISE = "PRECISE",
}

/**
 * Describes the general team inside the company structure
 */
export enum Position {
  QTEAM = "QTEAM",
  OFFICE = "OFFICE",
}

/**
 * A qualification gets generated by the user and holds information for employee
 * qualification
 */
export interface Qualification {
  id: string;
  createDate: Date;
  createdBy: string;
  name: string;
  description: string;
}

/**
 * Holds the daily work time information for an employee
 */
export interface WorkSchedule {
  id: string;
  dayOfWeek: DayOfWeek;
  workingHours: number;
  startTime?: number; // minutes of day
  endTime?: number; // minutes of day
}

/**
 * Bank account for userObject
 */
export interface BankAccount {
  owner: string;
  iban: string;
  bic: string;
  [key: string]: string;
}

/**
 * SimpleUser interface that consists of just the id, the firstName and the lastName of a user
 */
export interface SimpleUser {
  id: string;
  firstName: string;
  lastName: string;
  right?: UserRight;
  contractInformation: {
    employmentType: EmploymentType;
    weeklyWorkingTime: number;
  };
  customerTimes: Map<string, number>;
}

/**
 * Holds differend types of driver licenses needed for base data
 */
export enum DriverLicenseType {
  PKW = "PKW",
  LKW = "LKW",
  NONE = "NONE",
  UNKNOWN = "UNKNOWN",
}

/**
 * Holds the different levels of education needed for some base data
 */
export enum EducationLevel {
  NONE = "NONE",
  MITTEL_SCHULE = "MITTEL_SCHULE",
  MITTLERE_REIFE = "MITTLERE_REIFE",
  ABI = "ABI",
  UNKNOWN = "UNKNOWN",
}

/**
 * Holds the different qualification levels after an educational training
 */
export enum EducationQualification {
  NONE = "NONE",
  AUSBILDUNG = "AUSBILDUNG",
  MEISTER = "MEISTER",
  BACHELOR = "BACHELOR",
  MASTER = "MASTER",
  PROMOTION = "PROMOTION",
  UNKNOWN = "UNKNOWN",
}

/**
 * This constant holds the differend ways of people living together
 */
export enum FamilyState {
  UNKNOWN = "UNKNOWN",
  LEDIG = "LEDIG",
  EHE = "EHE",
  WITWE = "WITWE",
  SCHEIDUNG = "SCHEIDUNG",
  EHE_AUFGEHOBEN = "EHE_AUFGEHOBEN",
  PARTNERSCHAFT = "PARTNERSCHAFT",
  PARTNERSCHAFT_TOT = "PARTNERSCHAFT_TOT",
  PARTNERSCHAFT_AUFGEHOBEN = "PARTNERSCHAFT_AUFGEHOBEN",
  PARTNERSCHAFT_TOT_ERKL = "PARTNERSCHAFT_TOT_ERKL",
}

/**
 * Holds the german tax classes
 */
export enum TaxClass {
  UNKNOWN = "UNKNOWN",
  I = "I",
  II = "II",
  III = "III",
  IV = "IV",
  V = "V",
  VI = "VI",
}

/**
 * Request model containing the infomration to create the document
 */
export interface ProofOfActivityRequest {
  userIds: string[];
  period: Period;
  language: Language;
}
