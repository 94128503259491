import { PaymentCondition } from "../invoice/Invoice.types";
import { Contact } from "../user/User.types";

/**
 * interface for customers with all neccessary information
 */
export interface Customer {
  id: string;
  createDate: Date;
  lastUpdate: Date;
  createdBy: string;
  updatedBy: string;
  name: string;
  contact: Contact;
  vatNumber: string;
  icoNumber: string;
  invoiceRecipient: boolean;
  annotation: string;
  brancheId: string;
  disabled?: boolean;
  paymentCondition?: PaymentCondition;
  parentCompany?: string;
  internalName?: string;
  factored: boolean;
  internalAnnotation?: string;
  locationInfo?: string;
  numberRangeNumber: number;
  softLocked: boolean;
  hardLocked: boolean;
  customPrices: CustomerTaskPrice[];
  companyAccountTypes: Map<string, AccountType>;
}

/**
 * Interface for different branches that could be added by the users
 */
export interface Branche {
  id: string;
  createDate?: Date;
  lastUpdate?: Date;
  createdBy?: string;
  updatedBy?: string;
  name: string;
}

/**
 * In case there are special price agreements between schambeck and a customer,
 * they are defined in here
 */
export interface CustomerTaskPrice {
  id: string;
  officeId: string;
  taskId: string;
  price: number;
  extraCharge: number;
  vlPrice: number;
}

/**
 * Used to define in which relation a customer stands with an organisation
 */
export enum AccountType {
  INNER_COUNTRY = "INNER_COUNTRY",
  EU_OUTER_COUNTRY = "EU_OUTER_COUNTRY",
  THIRD_COUNTRY = "THIRD_COUNTRY",
}
