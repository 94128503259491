import { Country } from "../officialHoliday/OfficialHoliday.types";

/**
 * Represents a receiptEntity, containing information about the receipt and
 * connected order
 */
export interface Receipt {
  id: string;
  createDate: Date;
  createdBy: string;
  updatedBy: string;
  lastUpdate: Date;
  orderId: string;
  invoicePositions: Array<InvoicePosition>;
  invoiceState: InvoiceState;
  invoiceDate: Date;
  deliveryDate: Date;
  invoiceNumber: string;
  invoiceCreatedBy: string;
  paymentCondition: PaymentCondition;
  description: string;
  period: Period;
  shownPeriod: Period;
  customerOrderNumber: string;
  orderDetails: string;
  dunningLevel: number;
  paymentNote: string;
  protocolIds: string[];
  officeId: string;
  projectId: string;
  receiptType: ReceiptType;
  relatesTo?: string;
  invalidatedAt?: Date;
}

/**
 * Specifies the type for a Receipt
 * CANCEL is the so called "Storno" and CREDIT_NOTE is the "Gutschrift"
 */
export enum ReceiptType {
  INVOICE = "INVOICE",
  CANCEL = "CANCEL",
  CREDIT_NOTE = "CREDIT_NOTE",
}
/**
 * Model for payment conditions that could include multiple timespans to pay
 * regular or with a discount
 */
export interface PaymentCondition {
  id: string;
  createdBy: string;
  createDate: Date;
  updatedBy: string;
  lastUpdated: Date;
  description: string;
  daysToPayDiscount: number;
  daysToPay: number;
  discountPercentage: number;
  identifier: string;
  country: Country;
  deactivated: boolean;
}
/**
 * single position on the invoice
 */
export interface InvoicePosition {
  id: string;
  description: string;
  amount: number;
  index: number;
  articleNumber: string;
  discount: number;
  price: number;
}

/**
 * Different states for an invoice to keep track of it
 */
export enum InvoiceState {
  CREATED = "CREATED",
  PENDING = "PENDING",
  PAID = "PAID",
}

/**
 * Class for a timePeriod that contains start and endDate of the period
 */
export interface Period {
  startDate: Date;
  endDate: Date;
}

/**
 * Interface for a payment with information about the corrosponding order and
 * invoice
 */
export interface InvoicePayment {
  id: string;
  createDate: Date;
  createdBy: string;
  lastUpdate?: Date;
  updatedBy?: string;
  customerId: string;
  orderId: string;
  invoiceId: string;
  paymentDate: Date;
  amount: number;
  completePayment: boolean;
}

/**
 * enum to select how to create a invoicePosition for the documented work
 */
export enum ProjectInvoiceType {
  TIME_HOURS = "TIME_HOURS",
  TIME_DAYS = "TIME_DAYS",
  AMOUNT = "AMOUNT",
}
