/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Column,
  ColumnFiltersState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useEffect, useMemo, useState } from "react";
import { ReactComponent as ChevronDown } from "../../assets/chevron-down.svg";

import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ReactComponent as FilterIcon } from "../../assets/filter.svg";
import { Checkbox } from "../Checkbox/Checkbox";
import "./Table.scss";
import { TableComponentProps, TableHeader, TableRow } from "./Table.types";

const COLUMN_TYPE = "COLUMN";

/**
 * Helper model for react dnd
 */
type DragItem = {
  index: number;
  id: string;
  type: string;
};

/**
 * Render gate to decide if the complete table should be rendered or a
 * fallback component. This is needed to minimize the rerender logic
 */
export const Table: React.FC<TableComponentProps> = ({
  rows,
  header = [],
  paginationConfig = {
    pageSize: 30,
  },
  filterValue = "",
}) => {
  const renderOutput = useMemo(() => {
    if (rows.length > 0)
      return (
        <InternalTable
          rows={rows}
          header={header}
          paginationConfig={paginationConfig}
          filterValue={filterValue}
        />
      );
    return <h2>no data :(</h2>;
  }, [filterValue, header, paginationConfig, rows]);

  return renderOutput;
};

const Filter = ({ column }: { column: Column<any, unknown> }) => {
  const columnFilterValue = column.getFilterValue();
  return (
    <input
      value={
        typeof columnFilterValue === "string"
          ? columnFilterValue
          : typeof columnFilterValue === "number"
          ? columnFilterValue.toString()
          : ""
      }
      onChange={(e) => {
        column.setFilterValue(e.target.value || undefined);
      }}
      placeholder={`Filter`}
    />
  );
};

/**
 * The _actual_ table component
 */
const InternalTable: React.FC<TableComponentProps> = ({
  rows,
  header = [],
  filterValue = "",
}) => {
  const [visibleChild, setVisibleChild] = useState<string>("");
  const [showFilter, toggleFilter] = useState<boolean>(false);
  const [localHeader, setLocalHeader] = useState<TableHeader[]>([...header]);
  const [updatedRows, setUpdatedRows] = useState<TableRow[]>(rows);

  /**
   * Hook to trigger a rerender everytime the rows change
   */
  useEffect(() => {
    setUpdatedRows(rows);
  }, [rows]);

  /**
   * Holds the react table columns to display
   */
  const columns = React.useMemo(() => {
    if (!rows.length) return [];
    const columnHelper = createColumnHelper<TableRow>();

    if (
      rows.some(
        (row) =>
          row.children && !localHeader.some((header) => header.text === "")
      )
    )
      localHeader.push({ text: "", visible: true });
    if (rows.some((row) => row.children)) {
      rows.forEach((row) =>
        row.content.push(
          <ChevronDown
            className="table-component__sorting-indicator"
            onClick={() =>
              setVisibleChild((prev) => (prev === row.id ? "" : row.id))
            }
          />
        )
      );
    }

    const localCols = localHeader.map((h, index) => {
      return columnHelper.accessor((row) => row.content[index], {
        header: h.text,
        cell: (row) => row.row.original.content[index],
        footer: (column) => {
          if (localHeader.some((header) => header.showSum)) {
            let sum: number = 0;
            const rows = column.table.getRowModel().rows;

            rows.forEach((row) => {
              const value: unknown = row.getValue(column.column.id);
              if (typeof value === "number") {
                sum += value;
              } else if (!isNaN(Number(value))) {
                sum += Number(value);
              }
            });

            return sum === 0 ? "" : sum.toFixed(2);
          }
          return "";
        },
        id: `column_${index}`,
        enableColumnFilter: true,
      });
    });

    return localCols;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localHeader]);

  const data: TableRow[] = React.useMemo(() => {
    if (!updatedRows.length) return [];
    return updatedRows
      .filter(
        (row) =>
          filterValue === "" ||
          row.content
            .toString()
            .trim()
            .toLowerCase()
            .includes(filterValue.trim().toLowerCase())
      )
      .map((row) => ({
        ...row,
        content: row.content,
        expandable: row.children ? true : false,
        onClick: row.onClick,
        id: row.id,
      }));
  }, [filterValue, updatedRows]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const tableInstance = useReactTable({
    columns,
    data,
    state: {
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(), //client side filtering
  });

  const updateLocalHeaderFilter = (index: number): void => {
    const workingCopy: TableHeader[] = [...localHeader];
    workingCopy[index].visible = !localHeader[index].visible;
    setLocalHeader(workingCopy);
  };

  /**
   * Callback function to handle the column drag and drop
   *
   * @param dragIndex The original index of the column
   * @param hoverIndex The new target index of the column
   */
  const moveColumn = (dragIndex: number, hoverIndex: number) => {
    const draggedColumn = localHeader[dragIndex];
    const updatedColumns = [...localHeader];
    updatedColumns.splice(dragIndex, 1);
    updatedColumns.splice(hoverIndex, 0, draggedColumn);

    setLocalHeader(updatedColumns);

    const localRows: TableRow[] = updatedRows.map((row) => {
      const updatedContent = [...row.content];
      // the actual reordering
      const [movedContent] = updatedContent.splice(dragIndex, 1);
      updatedContent.splice(hoverIndex, 0, movedContent);

      return {
        ...row,
        content: updatedContent,
      };
    });

    setUpdatedRows(localRows);
  };

  return (
    <div className="table-component">
      <DndProvider backend={HTML5Backend}>
        <div className="table-component__filter__wrapper">
          <div className="table-component__filter__icon__wrapper">
            <FilterIcon
              className="table-component__filter__icon"
              onClick={() => toggleFilter((old) => !old)}
            />
          </div>
          {showFilter && (
            <div className="table-component__filter__entry__wrapper">
              {localHeader.map((head, index) => (
                <div className="table-component__filter__entry">
                  <Checkbox
                    isChecked={head.visible || false}
                    onCheck={() => updateLocalHeaderFilter(index)}
                    label={head.text}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <table>
          <thead>
            {tableInstance.getHeaderGroups().map((headerGroup) => (
              <tr {...headerGroup.headers} key={headerGroup.id}>
                {headerGroup.headers.map(
                  (column: any, index: number) =>
                    localHeader[index]?.visible && (
                      <DraggableColumnHeader
                        key={column.id}
                        column={column}
                        index={index}
                        moveColumn={moveColumn}
                      />
                    )
                )}
              </tr>
            ))}
          </thead>
          <tbody>
            {tableInstance.getRowModel().rows.map((row: any) => {
              return (
                <React.Fragment key={row.id}>
                  <tr
                    onClick={() => row.original.onClick?.()}
                    key={`tr-${row.original.id}`}
                  >
                    {row
                      .getVisibleCells()
                      .filter(
                        (_: any, index: number) => localHeader[index]?.visible
                      )
                      .map((cell: any) => {
                        return (
                          <td
                            style={{
                              cursor: row.original.onClick ? "pointer" : "",
                              backgroundColor:
                                filterValue !== "" &&
                                cell
                                  .getValue()
                                  .toString()
                                  .toLocaleLowerCase()
                                  .includes(filterValue.toLocaleLowerCase())
                                  ? "#ffdd33"
                                  : "",
                            }}
                            key={`td-${row.original.id}`}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                  </tr>

                  {row.original.id === visibleChild &&
                    row.original.children && (
                      <tr>
                        <td colSpan={columns.length}>
                          {row.original.children}
                        </td>
                      </tr>
                    )}
                </React.Fragment>
              );
            })}
          </tbody>
          <tr key="sumrow-footer" className="sumrow">
            {tableInstance.getFooterGroups().flatMap((footerGroup) =>
              footerGroup.headers.map((header, index) => {
                return (
                  localHeader[index].visible && (
                    <td key={header.id} className="sumrow__cell">
                      {flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                    </td>
                  )
                );
              })
            )}
          </tr>
        </table>
        <div>
          <button
            onClick={() => tableInstance.previousPage()}
            disabled={!tableInstance.getCanPreviousPage()}
          >
            Previous
          </button>
          <span>
            {"    "}
            <strong>{+1}</strong>
            {"    "}
          </span>
          <button
            onClick={() => tableInstance.nextPage()}
            disabled={!tableInstance.getCanNextPage()}
          >
            Next
          </button>
        </div>
      </DndProvider>
    </div>
  );
};

const DraggableColumnHeader: React.FC<{
  column: any;
  index: number;
  moveColumn: (dragIndex: number, hoverIndex: number) => void;
}> = ({ column, index, moveColumn }) => {
  const ref = React.useRef<HTMLTableCellElement>(null);

  const [, drop] = useDrop({
    accept: COLUMN_TYPE,
    hover(item: DragItem) {
      if (!ref.current) {
        return;
      }
      const dragIndex: number = item.index;
      const hoverIndex: number = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      moveColumn(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: COLUMN_TYPE,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <th
      ref={ref}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
      }}
    >
      <div
        className="table-component__head-wrapper"
        onClick={column.column.getToggleSortingHandler()}
      >
        {flexRender(column.column.columnDef.header, column.getContext())}
        {{
          asc: " 🔼",
          desc: " 🔽",
        }[column.column.getIsSorted() as string] ?? null}
      </div>
      <div className="table-component__filter-wrapper">
        {column.column.getCanFilter() ? (
          <Filter column={column.column} />
        ) : null}
      </div>
    </th>
  );
};
