import { Dropdown, Input, Option, TableRow } from "@sam/components";
import { Dispatch, SetStateAction } from "react";
import { NavigateFunction } from "react-router-dom";
import {
  Company,
  Customer,
  CustomerUser,
  DeliveryNote,
  NumberRange,
  Offer,
  OfferProvidedBy,
  OfferState,
  OfferType,
  OfferedTask,
  Order,
  OrderState,
  ProjectInvoiceType,
  StandardPriceType,
  Task,
} from "shared";
import { CustomerLocation } from "shared/src/customerLocation/CustomerLocation.types";
import { Language } from "shared/src/Language/Language.types";
import { uid } from "uid";
import { ReactComponent as CopyIcon } from "../../assets/copy.svg";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import i18n from "../../i18n/i18n";
import { generateEmptyTask } from "../task/task.utils";

/**
 * Util method to generate an empty Offer
 * @param override override setter to override selected keys
 * @returns  empty offer object with data of the override setter
 */
export const generateEmptyOffer = (override?: Partial<Offer>): Offer => ({
  acceptDate: undefined,
  annotation: "",
  createDate: new Date(),
  createdBy: "",
  deniedDate: undefined,
  id: undefined!,
  language: Language.DE,
  lastUpdate: undefined!,
  offeredTasks: [],
  orderId: "",
  plannedAmountWorker: 1,
  providedBy: OfferProvidedBy.UNKNOWN,
  updatedBy: "",
  offerState: OfferState.OFFER_PREP,
  customerId: "",
  bottomInfo: false,
  topInfo: false,
  showSum: false,
  offerType: OfferType.STANDARD,
  ...override,
});

/**
 * Helper to quickly generate a new offer with the given standard tasks
 * and overriden parameters
 *
 * @param standardTasks The loaded standard tasks
 * @param override Optional parameters to override on the offer
 * @returns A new offer instance
 */
export const generateEmptyOfferWithStandardTasks = (
  standardTasks: Task[] = [],
  override?: Partial<Offer>
): Offer => {
  const newOffer: Offer = generateEmptyOffer(override);
  standardTasks.forEach((task, index) =>
    newOffer.offeredTasks.push({
      amount: 1,
      annotation: "",
      id: uid(),
      index,
      task,
      totalAmount: task.price,
    })
  );
  return newOffer;
};

/**
 * Util method to generate an empty Order
 * @param override  setter to adjust the created object
 * @returns  empty order object with data of the override setter
 */
export const generateEmptyOrder = (override?: Partial<Order>): Order => ({
  createDate: new Date(),
  createdBy: "",
  customerContact: "",
  customerId: "",
  id: undefined!,
  lastUpdate: undefined!,
  locationContactId: "",
  locationId: "",
  officeId: "",
  orderState: OrderState.OFFER_PREP,
  updatedBy: "",
  additionalReceivers: [],
  shifts: [],
  companyId: "",
  businessAreaId: "",
  customerFeedback: new Map<string, number>(),
  workingLocation: {
    customerId: "",
    customerLocationId: "",
    customerContactId: "",
  },
  pieces: 0,
  workingLocationComment: "",
  customerOrderNumber: "",
  invoiceRecipient: {
    customerId: "",
    customerUserId: "",
  },
  invoiceType: ProjectInvoiceType.TIME_HOURS,
  currencyId: "",
  customerArticleDescription: "",
  customerArticleNumber: "",
  offerType: OfferType.STANDARD,
  ...override,
});

/**
 * Util method to convert offers into TableEntries
 * @param offers array of all offers to display as tableRows
 * @param customers array of customers to find the customer name
 * @returns array of TableRows
 */
export const convertOffersIntoTableEntries = (
  offers: Offer[],
  orders: Order[],
  customers: Customer[],
  locations: CustomerLocation[],
  customerContacts: CustomerUser[],
  navigate: NavigateFunction,
  copyOffer?: (offerId: string) => void
): TableRow[] => {
  return offers.map((offer) => {
    const order: Order | undefined = orders.find(
      (order) => order.id === offer.orderId
    );

    const workLocation: string =
      locations.find(
        (customer) => customer.id === order?.workingLocation.customerLocationId
      )?.name || "-";
    const customerContact: CustomerUser | undefined = customerContacts.find(
      (contact) => contact.id === order?.customerContact
    );
    return {
      id: offer.id,
      onClick: () =>
        copyOffer
          ? navigate("/offer/create", { state: { offer: offer } })
          : navigate("/customer/project/edit", { state: { offer, order } }),
      content: [
        offer.numberRangeNumber || "-",
        customers.find((customer) => customer.id === offer.customerId)?.name ||
          "-",
        workLocation,
        customerContact
          ? customerContact?.firstName + " " + customerContact?.lastName
          : "-",
        offer.annotation,
        offer.createDate.toLocaleDateString("DE-de"),
        i18n.t(
          `pages.order.overview.offerState.${
            offer.offerState ? offer.offerState : "OFFER"
          }`
        ),
        <div className="table-action__wrapper">
          <EditIcon
            width={30}
            onClick={(evt) => {
              evt.stopPropagation();
              copyOffer
                ? navigate("/offer/create", { state: { offer: offer } })
                : navigate("/customer/project/edit", {
                    state: { offer, order },
                  });
            }}
          />
          {copyOffer && (
            <CopyIcon
              width={30}
              onClick={(evt) => {
                evt.stopPropagation();
                copyOffer(offer.id);
              }}
            />
          )}
        </div>,
      ],
    };
  });
};

/**
 * Util method to generate an empty task
 * @param override to adjust generated object
 * @returns empty offeredTask
 */
export const generateEmptyOfferedTask = (
  override?: Partial<OfferedTask>
): OfferedTask => ({
  amount: 0,
  annotation: "",
  id: uid(),
  index: 1,
  task: generateEmptyTask(),
  totalAmount: 0,
  ...override,
});

/**
 * Util method to convert offered tasks into TableEntries with editable content
 * @param offeredTasks to display in the table
 * @param tasks list of all tasks
 * @param setOfferToCreate dispatch method to edit the tableEntries
 * @returns array of created tableRows
 */
export const convertOfferedTasksIntoTableEntries = (
  offeredTasks: OfferedTask[],
  tasks: Task[],
  officeId: string,
  currencyId: string,
  setOfferedTaskIdToDelete?: Dispatch<SetStateAction<string | undefined>>,
  setOfferToCreate?: Dispatch<SetStateAction<Offer>>,
  readOnly?: boolean
): TableRow[] => {
  return offeredTasks
    .filter((task) => !!task.id)
    .map((rowTask, index) => ({
      id: rowTask.id,
      content: [
        index + 1,
        <Dropdown
          disabled={readOnly}
          options={tasks.map((task) => ({ label: task.title, value: task.id }))}
          selectedOption={rowTask.task.id}
          onChange={(taskId) => {
            const selectedTask: Task | undefined = tasks.find(
              (task) => task.id === taskId
            );
            if (setOfferToCreate && selectedTask) {
              const newTasks: OfferedTask[] = offeredTasks.map((task) =>
                rowTask.id === task.id
                  ? {
                      ...task,
                      task: {
                        ...selectedTask,
                        price:
                          selectedTask.officePrices
                            ?.get(officeId)
                            ?.get(currencyId) || selectedTask.price,
                      },
                      amount: 1,
                      totalAmount:
                        selectedTask.officePrices
                          ?.get(officeId)
                          ?.get(currencyId) || selectedTask.price,
                    }
                  : task
              );
              setOfferToCreate((prevValue) => ({
                ...prevValue,
                offeredTasks: newTasks,
              }));
            }
          }}
        />,
        <Input
          type="text"
          value={rowTask.task.description}
          onChange={(description) => {
            if (!setOfferToCreate) return;
            const updatedTasks: OfferedTask[] = offeredTasks.map((singleTask) =>
              singleTask.id === rowTask.id
                ? {
                    ...singleTask,
                    task: { ...singleTask.task, description },
                  }
                : singleTask
            );
            setOfferToCreate((prevValue) => ({
              ...prevValue,
              offeredTasks: updatedTasks,
            }));
          }}
        />,
        <Input
          disabled={readOnly}
          value={rowTask.amount}
          type="number"
          onChangeNumber={(amount) => {
            if (setOfferToCreate) {
              const updatedTasks: OfferedTask[] = offeredTasks.map((task) =>
                task.id === rowTask.id
                  ? {
                      ...task,
                      totalAmount: rowTask.task.price * amount,
                      amount,
                    }
                  : task
              );
              setOfferToCreate((prevValue) => ({
                ...prevValue,
                offeredTasks: updatedTasks,
              }));
            }
          }}
        />,
        <Input type="text" disabled value={rowTask?.task?.unit || ""} />,
        <Input
          type="number"
          disabled
          value={rowTask?.task?.amount || 1}
          onChangeNumber={() => {}}
        />,
        <Input
          disabled={readOnly}
          value={rowTask.task.price}
          type="number"
          onChangeNumber={(price) => {
            if (setOfferToCreate) {
              const newTasks: OfferedTask[] = offeredTasks.map((task) =>
                rowTask.id === task.id
                  ? {
                      ...task,
                      task: { ...rowTask.task, price },
                      totalAmount: rowTask.amount * price,
                    }
                  : task
              );
              setOfferToCreate((prev) => ({ ...prev, offeredTasks: newTasks }));
            }
          }}
        />,
        <Input
          value={rowTask.totalAmount}
          type="number"
          disabled
          onChangeNumber={() => {}}
        />,
        !readOnly && setOfferedTaskIdToDelete ? (
          <DeleteIcon
            width={30}
            color="#BC2E46"
            onClick={(evt) => {
              evt.stopPropagation();
              setOfferedTaskIdToDelete(rowTask.id);
            }}
          />
        ) : (
          <></>
        ),
      ],
    }));
};

/**
 * Util method to convert numberRanges into TableEntries
 * @param ranges  to convert into TableEntries
 * @param companies to get the name of the companies for the different number Ranges
 * @returns Array of tableRows with the name and value
 */
export const convertNumberRangesIntoTableEntries = (
  ranges: NumberRange[],
  companies: Company[]
): TableRow[] => {
  return ranges.map((range) => ({
    id: range.id,
    content: [
      i18n.t(`pages.numberRanges.type.${range.type}`),
      companies.find((company) => company.id === range.companyId)?.name || "-",
      range.value,
    ],
  }));
};

export const generateDropdownOptionsForCustomers = (
  customers: Customer[]
): Option[] => {
  return customers
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((customer) => ({
      label: customer.name,
      value: customer.id,
    }));
};

export const generateDropdownOptionsForCustomerUsers = (
  customerUsers: CustomerUser[],
  customerIdToFilter?: string
): Option[] => {
  let filteredUser: CustomerUser[] = customerUsers;
  if (customerIdToFilter) {
    filteredUser = filteredUser.filter(
      (customerUser) => customerUser.customerId === customerIdToFilter
    );
  }
  return filteredUser
    .sort((a, b) => a.lastName.localeCompare(b.lastName))
    .map((customerUser) => ({
      label: `${customerUser.lastName}, ${customerUser.firstName}`,
      value: customerUser.id,
    }));
};

/**
 * Util method to generate an empty deliveryNote
 * @param override partial deliveryNote to adjust created object
 * @returns generated DeliveryNote
 */
export const generateEmptyDeliveryNote = (
  override: Partial<DeliveryNote>
): DeliveryNote => ({
  deliveryDate: new Date(),
  annotation: "",
  createDate: new Date(),
  createdBy: "",
  currencyId: "",
  id: undefined!,
  language: Language.DE,
  offeredTasks: [],
  orderId: "",
  performancePeriod: { startDate: new Date(), endDate: new Date() },
  numberRangeNumber: 0,
  ...override,
});

/**
 * Util method to check if a standard price has a price in percentage or an absolut price
 * @param type standardPricType to checl
 * @returns boolean true for percentage or false for absolute
 * @tested
 */
export const isPercentagePrice = (type?: StandardPriceType): boolean =>
  (type && type === StandardPriceType.WORK_HOLIDAY) ||
  type === StandardPriceType.WORK_NIGHT ||
  type === StandardPriceType.WORK_SATURDAY ||
  type === StandardPriceType.WORK_SUNDAY;
