import { uid } from "uid";
import {
  Car,
  CarType,
  FuelType,
  TransmissionType,
  VehicleContractInformation,
} from "./Car.types";

/**
 * Util method to create an empty car
 * @param userId to fill createdBy and updatedBy
 * @returns empty Car object
 */
export const generateEmptyCar = (override?: Partial<Car>): Car => {
  return {
    brand: "",
    milageHistories: [],
    color: "",
    createDate: new Date(),
    createdBy: "",
    enginePowerKw: 0,
    fuelType: FuelType.OTHER,
    grossListPrice: 0,
    id: undefined!,
    lastUpdated: new Date(),
    milage: 0,
    model: "",
    registrationDate: new Date(),
    registrationPlate: "",
    transmissionType: TransmissionType.MANUAL,
    typeKey: { hsn: "", tsn: "" },
    updatedBy: "",
    userId: "",
    availableFrom: new Date(),
    comments: [],
    dealer: "",
    disabled: false,
    generalInspection: new Date(),
    officeId: "",
    restricted: false,
    restrictedReason: "",
    seats: 0,
    vehicleContractInformation: generateEmptyVehicleContractInformation(),
    active: false,
    type: CarType.UNKNOWN,
    poolUsage: false,
    ...override,
  };
};

/**
 * Util method to generate an empty vehicleContractInformation
 * @param override partial information to adjust the created object
 * @returns generated object
 */
export const generateEmptyVehicleContractInformation = (
  override?: Partial<VehicleContractInformation>
): VehicleContractInformation => ({
  fuelCardDKV: "",
  fuelCardStraehuber: "",
  id: uid(),
  insurance: 0,
  insuranceNumber: "",
  leasingEnd: new Date(),
  leasingNumber: "",
  leasingRate: 0,
  pinDKV: "",
  pinStraehuber: "",
  priceKmOver: 0,
  priceKmUnder: 0,
  tax: 0,
  winterTyres: false,
  yearlyMilage: 15000,
  ...override,
});
