import { TableRow } from "@sam/components";
import {
  AgendaEntry,
  AgendaEntryGroup,
} from "@sam/components/src/Agenda/Agenda.types";
import { NavigateFunction } from "react-router-dom";
import {
  AbsenceReason,
  Car,
  MilageHistory,
  Project,
  Right,
  Schedule,
  SimpleUser,
  UserRight,
  generateIsoDateForDateAndTime,
} from "shared";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import i18n from "../../i18n/i18n";
import { isUserAllowedToDo } from "../user/User.utils";

/**
 * Util method to convert a car into TableRows for TableComponent
 * @param userRights rights of the user to check if editIcon should be shown
 * @param cars Array of cars to convert into TableRows
 * @param navigate NavigateFunction to navigate to edit
 * @returns Array of TableRows
 */
export const convertCarIntoTableEntries = (
  userRights: UserRight,
  cars: Car[],
  navigate: NavigateFunction
): TableRow[] => {
  return cars.map((car) => ({
    id: car.id,
    onClick: () => navigate("/cars/edit", { state: { carToEdit: car } }),
    content: [
      car.registrationPlate,
      `${car.brand} ${car.model}`,
      i18n.t(`pages.carCreateOrEdit.car.fuelTypes.${car.fuelType}`),
      car.registrationDate.toLocaleDateString(),
      <div>
        {isUserAllowedToDo(userRights, Right.CAR_EDIT) && (
          <EditIcon
            width={40}
            onClick={(evt) => {
              evt.stopPropagation();
              navigate("/cars/edit", { state: { carToEdit: car } });
            }}
          />
        )}
      </div>,
    ],
  }));
};

/**
 * Helper to transform the given milage history into table entries to display
 *
 * @param milages The milage history entries
 * @param user All simple user
 * @returns  The table rows
 */
export const convertMilageHistoryInTableEntries = (
  milages: MilageHistory[],
  user: SimpleUser[]
): TableRow[] => {
  return milages.map((milage) => ({
    content: [
      milage.createDate.toLocaleDateString(),
      user.find((user) => user.id === milage.createdBy)?.lastName || "-",
      milage.previousMilage,
      milage.currentMilage,
    ],
    id: milage.id,
  }));
};

/**
 * Util to convert the given schedules into a form so that an Agenda can display it
 *
 * @param schedules All schedule entries to display
 * @param userId The id of the corresponding user
 * @param allProjects List of all projects
 * @param allAbsenceReasons All configured absence reasons
 * @returns The Schedules as agenda entry
 */
export const generateCarAgendaScheduleEntries = (
  schedules: Schedule[],
  carId: string,
  allProjects: Project[],
  allAbsenceReasons: AbsenceReason[]
): AgendaEntryGroup[] => {
  const entries: AgendaEntry[] = schedules.map((schedule) => ({
    id: schedule.id,
    title:
      schedule.appointmentDetail?.title ||
      allProjects.find((project) => project.id === schedule.referenceId)
        ?.numberRangeNumber ||
      i18n.t("pages.user.create.schedule.projectPlaceholder"),
    background: schedule.appointmentDetail
      ? allAbsenceReasons.find(
          (reason) => reason.id === schedule.appointmentDetail?.reasonId
        )?.color || "lightcyan"
      : "#D3F4E4",
    start: generateIsoDateForDateAndTime(
      schedule.scheduleDate,
      schedule.appointmentDetail?.startTime || 0
    ),
    end: generateIsoDateForDateAndTime(
      schedule.scheduleDate,
      schedule.appointmentDetail?.endTime || 1440
    ),
  }));
  return [{ id: carId, entries, groupName: "" }];
};
