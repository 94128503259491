import { Box, Dropdown, Input, Option, Table, TopBar } from "@sam/components";
import { TableHeader, TableRow } from "@sam/components/src/Table/Table.types";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Country,
  Pdl,
  PdlUser,
  createNewPdl,
  generateNotification,
  getUserForPdl,
  updatePdl,
  useData,
} from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import { ReactComponent as AddIcon } from "../../assets/plus.svg";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { useUser } from "../../components/UserContext";
import {
  convertPdlUserIntoTableEntries,
  generateEmptyPdl,
  generateEmptyPdlUser,
} from "../../utils/pdl/Pdl.utils";

export const PdlEdit: React.FC = () => {
  const { axios } = useUser();
  const location = useLocation<{ pdl?: Pdl }>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [pdlToEdit, setPdlToEdit] = useState<Pdl>(
    location.state?.pdl || generateEmptyPdl()
  );
  const [pdlUser, setPdlUser] = useState<PdlUser[]>([]);
  const [dropdownOptions, setDropdownOptions] = useState<Option[]>([]);
  const { data: allBranches } = useData("BRANCHES_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allOffices } = useData("OFFICES_ALL", {
    config: { fallbackData: [] },
  });

  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);

  /**
   * holds the pdluser table rows
   */
  const pdlUserRows: TableRow[] = useMemo((): TableRow[] => {
    return convertPdlUserIntoTableEntries(pdlUser, navigate, allOffices, false);
  }, [pdlUser, navigate, allOffices]);

  /**
   * holds the pdluser contacts table rows
   */
  const pdlContactRows: TableRow[] = useMemo((): TableRow[] => {
    return convertPdlUserIntoTableEntries(pdlUser, navigate, allOffices, true);
  }, [pdlUser, navigate, allOffices]);

  //Hook to load all customerUsers and convert them into TableEntries if we are editing and not creating a new customer
  useEffect(() => {
    if (!pdlToEdit.id) return;
    getUserForPdl(pdlToEdit.id, axios).then(setPdlUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdlToEdit, navigate]);

  //UseEffect hook to set the dropdownOptions after all branches are loaded
  useEffect(
    () =>
      setDropdownOptions(
        allBranches.map((branch) => ({
          label: branch.name,
          value: branch.id,
        }))
      ),
    [allBranches]
  );

  /**
   * Submit handler that creates or edits a PDL
   * @param redirect decides if the method should navigate back after success
   */
  const handleSubmit = (redirect: boolean): void => {
    button.current?.click();
    if (!form.current?.checkValidity()) return;
    const backendCall: Promise<Pdl> = pdlToEdit.id
      ? updatePdl(pdlToEdit, axios)
      : createNewPdl(pdlToEdit, axios);
    backendCall.then((savedPdl) => {
      if (redirect && savedPdl) navigate(-1);
      else if (savedPdl)
        generateNotification({
          type: NotificationType.SUCCESS,
          value: t("general.notification.success.saveSuccessfull"),
        });
    });
  };

  return (
    <form
      className="customer-create__wrapper"
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      <TopBar
        onBackClick={() => navigate(-1)}
        title={
          location.state?.pdl
            ? t("pages.pdl.edit.topBarHeadlineEdit")
            : t("pages.pdl.edit.topBarHeadlineCreate")
        }
      >
        <SaveButtons handleSubmit={handleSubmit} buttonRef={button} />
      </TopBar>
      <Box title={t("pages.pdl.edit.baseData")}>
        <div className="three-columns">
          {pdlToEdit.id && (
            <Input
              disabled
              type="text"
              label={t("pages.pdl.edit.numberRangeNumber")}
              value={pdlToEdit.numberRangeNumber}
            />
          )}
          <Input
            required
            type="text"
            label={t("pages.pdl.edit.name")}
            value={pdlToEdit.name}
            onChange={(name) => setPdlToEdit({ ...pdlToEdit, name })}
          />
          <Input
            type="text"
            label={t("pages.pdl.edit.internalName")}
            value={pdlToEdit.internalName}
            onChange={(internalName) =>
              setPdlToEdit({ ...pdlToEdit, internalName })
            }
          />

          <Input
            type="text"
            label={t("pages.pdl.edit.internalMemo")}
            value={pdlToEdit.internalMemo}
            onChange={(internalMemo) =>
              setPdlToEdit({ ...pdlToEdit, internalMemo })
            }
          />
          <Dropdown
            label={t("pages.pdl.edit.branch")}
            options={dropdownOptions}
            onChange={(brancheId) => setPdlToEdit({ ...pdlToEdit, brancheId })}
            selectedOption={pdlToEdit.brancheId}
          />
          <Input
            type="text"
            value={pdlToEdit.company}
            onChange={(company) => setPdlToEdit({ ...pdlToEdit, company })}
            label={t("pages.pdl.edit.company")}
          />
          <Input
            type="text"
            value={pdlToEdit.vatId}
            onChange={(vatId) => setPdlToEdit({ ...pdlToEdit, vatId })}
            label={t("pages.pdl.edit.vatId")}
          />
        </div>
      </Box>
      <Box title={t("pages.pdl.edit.contactInformation")}>
        <div className="three-columns">
          <Input
            label={t("pages.pdl.edit.phone")}
            type="text"
            value={pdlToEdit.contact.phone}
            onChange={(phone) =>
              setPdlToEdit({
                ...pdlToEdit,
                contact: { ...pdlToEdit.contact, phone },
              })
            }
          />
          <Input
            type="text"
            value={pdlToEdit.contact.mobile}
            onChange={(mobile) =>
              setPdlToEdit({
                ...pdlToEdit,
                contact: { ...pdlToEdit.contact, mobile },
              })
            }
            label={t("pages.pdl.edit.mobile")}
          />

          <Input
            type="email"
            value={pdlToEdit.contact.mail}
            onChange={(mail) =>
              setPdlToEdit({
                ...pdlToEdit,
                contact: { ...pdlToEdit.contact, mail },
              })
            }
            label={t("pages.pdl.edit.mail")}
          />
          <Input
            type="text"
            value={pdlToEdit.contact.website || ""}
            onChange={(website) =>
              setPdlToEdit({
                ...pdlToEdit,
                contact: { ...pdlToEdit.contact, website },
              })
            }
            label={t("pages.pdl.edit.website")}
          />
          <Input
            type="text"
            value={pdlToEdit.contact.fax || ""}
            onChange={(fax) =>
              setPdlToEdit({
                ...pdlToEdit,
                contact: { ...pdlToEdit.contact, fax },
              })
            }
            label={t("pages.pdl.edit.fax")}
          />
        </div>
        <div className="three-columns">
          <Input
            type="text"
            value={pdlToEdit.contact.street}
            onChange={(street) =>
              setPdlToEdit({
                ...pdlToEdit,
                contact: { ...pdlToEdit.contact, street },
              })
            }
            label={t("pages.pdl.edit.street")}
          />
          <Input
            type="text"
            value={pdlToEdit.contact.streetNumber}
            onChange={(streetNumber) =>
              setPdlToEdit({
                ...pdlToEdit,
                contact: { ...pdlToEdit.contact, streetNumber },
              })
            }
            label={t("pages.pdl.edit.streetNumber")}
          />
          <Dropdown
            selectedOption={pdlToEdit.contact.country}
            label={t("pages.pdl.edit.country")}
            onChange={(country) =>
              setPdlToEdit({
                ...pdlToEdit,
                contact: {
                  ...pdlToEdit.contact,
                  country: country as Country,
                },
              })
            }
            options={Object.keys(Country).map((country) => ({
              label: t(`pages.pdl.edit.countries.${country}`),
              value: country as Country,
            }))}
          />
          <Input
            type="text"
            value={pdlToEdit.contact.zip}
            onChange={(zip) =>
              setPdlToEdit({
                ...pdlToEdit,
                contact: { ...pdlToEdit.contact, zip },
              })
            }
            label={t("pages.pdl.edit.zip")}
          />
          <Input
            type="text"
            value={pdlToEdit.contact.city}
            onChange={(city) =>
              setPdlToEdit({
                ...pdlToEdit,
                contact: { ...pdlToEdit.contact, city },
              })
            }
            label={t("pages.pdl.edit.city")}
          />
        </div>
      </Box>
      <Box>
        <div className="customer-create__box-header">
          <p className="customer-create__box-headline">
            {t("pages.pdl.edit.contacts")}
          </p>
          <AddIcon
            width={30}
            onClick={() =>
              navigate("/pdl/user/edit", {
                state: {
                  user: generateEmptyPdlUser({
                    pdlId: pdlToEdit.id,
                    pdlContact: true,
                  }),
                },
              })
            }
          />
        </div>
        <Table
          rows={pdlContactRows}
          header={
            t("pages.pdl.edit.userTableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
      {pdlToEdit.id && (
        <>
          <Box>
            <div className="customer-create__box-header">
              <p className="customer-create__box-headline">
                {t("pages.pdl.edit.users")}
              </p>
              <AddIcon
                width={30}
                onClick={() =>
                  navigate("/pdl/user/edit", {
                    state: {
                      user: generateEmptyPdlUser({
                        pdlId: pdlToEdit.id,
                        pdlContact: false,
                      }),
                    },
                  })
                }
              />
            </div>
            <Table
              rows={pdlUserRows}
              header={
                t("pages.pdl.edit.userTableHeader", {
                  returnObjects: true,
                }) as TableHeader[]
              }
            />
          </Box>
        </>
      )}
    </form>
  );
};
