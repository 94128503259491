import { CheckboxProps } from "./Checkbox.types";
import "./Checkbox.scss";
import { ReactComponent as Checkmark } from "../../assets/Checkmark.svg";
export const Checkbox: React.FC<CheckboxProps> = ({
  disabled,
  label,
  isChecked,
  onCheck,
}) => {
  return (
    <div
      data-testid="checkbox-component"
      className="checkbox-component__wrapper"
      onClick={() => disabled || onCheck(!isChecked)}
    >
      {isChecked ? (
        <div className="checkbox-component__checkbox__checked">
          <Checkmark
            className={[
              "checkbox-component__checkbox__checked__checkmark",
              disabled ? "disabled" : "",
            ].join(" ")}
          />
        </div>
      ) : (
        <div
          className={[
            "checkbox-component__checkbox__empty",
            disabled ? "disabled" : "",
          ].join(" ")}
        />
      )}
      {typeof label === "string" ? (
        <label className="checkbox-component__label">{label}</label>
      ) : (
        label
      )}
    </div>
  );
};
