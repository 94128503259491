import {
  Box,
  Button,
  Dropdown,
  Input,
  Popup,
  TopBar,
  Wysiwyg,
} from "@sam/components";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AdditionalInfoType,
  Country,
  County,
  MailType,
  Office,
  OfficeType,
  SmtpConfiguration,
  createNewOffice,
  generateDropdownOptions,
  generateNotification,
  sendTestMail,
  toggleOfficeDeactivated,
  updateOffice,
  useData,
} from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import { Right } from "shared/src/userRole/UserRole.types";
import { NotAllowed } from "../../components/NotAllowed";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { useUser } from "../../components/UserContext";
import {
  generateDropdownOptionsForOfficeType,
  generateEmptyOffice,
} from "../../utils/office/Office.utils";
import { isUserAllowedToDo } from "../../utils/user/User.utils";

const CreateOrEditOffice: React.FC = () => {
  const { user, axios } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation<{ office?: Office }>();
  const [showDisablePopup, setShowDisablePopup] = useState<boolean>(false);
  const [office, setOffice] = useState<Office>(
    location.state?.office || generateEmptyOffice()
  );
  const { data: loadedOffices } = useData("OFFICES_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allCompanies } = useData("COMPANY_ALL", {
    config: { fallbackData: [] },
  });

  const { data: loadedRoles } = useData("ROLES_ALL", {
    config: { fallbackData: [] },
  });

  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);

  /**
   * Helper method to handle submit
   * @param redirect decides if the method should navigate back after success
   * calls create or update depending of id param, which is set in edit mode
   */
  const handleSubmit = async (redirect: boolean): Promise<void> => {
    const savedOffice: Office | undefined = office.id
      ? await updateOffice(axios, office)
      : await createNewOffice(axios, office);
    if (savedOffice && redirect) navigate("/office");
    else if (savedOffice) {
      setOffice(savedOffice);
      generateNotification({
        type: NotificationType.SUCCESS,
        value: t("general.notification.success.saveSuccessfull"),
      });
    }
  };

  /**
   * Helper method to update the smtpConfiguration of the office
   * @param newValue new Value to set
   * @param key key of the configuration to update
   * @param type decides if the general or the account config should be updated
   */
  const updateMailConfig = (
    newValue: string,
    key: keyof SmtpConfiguration,
    type: "GENERAL" | "ACCOUNT"
  ): void =>
    setOffice({
      ...office,
      accountingMailConfiguration:
        type === "ACCOUNT"
          ? { ...office.accountingMailConfiguration, [key]: newValue }
          : office.accountingMailConfiguration,
      generalMailConfiguration:
        type === "GENERAL"
          ? { ...office.generalMailConfiguration, [key]: newValue }
          : office.generalMailConfiguration,
    });

  /**
   * Util method to handle the Change of the officeType, that clears the office id if it is no service point
   * @param officeType selected officeType
   */
  const handleChangeOfficeType = (officeType: OfficeType) =>
    officeType === OfficeType.SERVICE_POINT
      ? setOffice({
          ...office,
          officeType: officeType,
        })
      : setOffice({
          ...office,
          officeType: officeType,
          officeId: undefined,
        });

  return isUserAllowedToDo(user.right, Right.OFFICE_CREATE) ? (
    <form
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      <TopBar
        onBackClick={() => navigate(-1)}
        title={t("pages.office.edit.headline")}
      >
        <SaveButtons handleSubmit={handleSubmit} buttonRef={button} />
      </TopBar>
      <Box>
        <Popup
          title={t("pages.customerUser.createOrEdit.partialPaymentPopupTitle")}
          buttons={[
            <Button
              value={t("general.buttons.yes")}
              onClick={() => {
                toggleOfficeDeactivated(axios, office.id).then(() =>
                  navigate("/office")
                );
              }}
            />,
            <Button
              value={t("general.buttons.no")}
              onClick={() => setShowDisablePopup(false)}
            />,
          ]}
          isOpen={showDisablePopup}
          onClose={() => setShowDisablePopup(false)}
        >
          <p>
            {office.deactivated
              ? t("general.popup.activateOffice", {
                  replace: { officeName: office.name },
                })
              : t("general.popup.deactivateOffice", {
                  replace: { officeName: office.name },
                })}
          </p>
        </Popup>
        <div className="three-columns">
          <Input
            type="text"
            value={office.name}
            onChange={(name) => setOffice({ ...office, name })}
            label={t("pages.office.edit.name")}
          />
          <Input
            type="number"
            value={office.numberIdentifier}
            onChangeNumber={(numberIdentifier) =>
              setOffice({ ...office, numberIdentifier })
            }
            label={t("pages.office.edit.numberIdentifier")}
          />
          <Input
            value={office.contact.fax}
            type="text"
            label={t("pages.office.edit.fax")}
            onChange={(fax) =>
              setOffice({ ...office, contact: { ...office.contact, fax } })
            }
          />
          <Input
            type="text"
            value={office.contact.phone}
            label={t("pages.office.edit.phone")}
            onChange={(phone) =>
              setOffice({
                ...office,
                contact: { ...office.contact, phone },
              })
            }
          />
          <Input
            type="text"
            value={office.contact.mobile}
            label={t("pages.office.edit.mobile")}
            onChange={(mobile) =>
              setOffice({ ...office, contact: { ...office.contact, mobile } })
            }
          />
          <Input
            type="email"
            value={office.contact.mail}
            label={t("pages.office.edit.mail")}
            onChange={(mail) =>
              setOffice({ ...office, contact: { ...office.contact, mail } })
            }
          />
          <Input
            type="text"
            value={office.contact.phonePrivate}
            label={t("pages.office.edit.phonePrivate")}
            onChange={(phonePrivate) =>
              setOffice({
                ...office,
                contact: { ...office.contact, phonePrivate },
              })
            }
          />
          <Input
            type="text"
            value={office.contact.mobilePrivate}
            label={t("pages.office.edit.mobilePrivate")}
            onChange={(mobilePrivate) =>
              setOffice({
                ...office,
                contact: { ...office.contact, mobilePrivate },
              })
            }
          />
          <Input
            type="email"
            value={office.contact.mailPrivate}
            label={t("pages.office.edit.mailPrivate")}
            onChange={(mailPrivate) =>
              setOffice({
                ...office,
                contact: { ...office.contact, mailPrivate },
              })
            }
          />
        </div>
        <div className="three-columns">
          <Dropdown
            label={t("pages.office.edit.country")}
            options={Object.keys(Country).map((country) => ({
              label: t(`pages.office.edit.countries.${country}`),
              value: country as Country,
            }))}
            onChange={(country) =>
              setOffice({
                ...office,
                contact: { ...office.contact, country: country as Country },
              })
            }
            selectedOption={office.contact.country}
          />
          {office.contact.country === Country.GERMANY && (
            <Dropdown
              label={t("pages.office.edit.county")}
              options={Object.values(County).map((county) => ({
                label: t(`pages.office.edit.counties.${county}`),
                value: county,
              }))}
              onChange={(county) =>
                setOffice({
                  ...office,
                  contact: { ...office.contact, county: county as County },
                })
              }
              selectedOption={office.contact.county}
            />
          )}
          <Input
            type="text"
            value={office.contact.street}
            label={t("pages.office.edit.street")}
            onChange={(street) =>
              setOffice({
                ...office,
                contact: { ...office.contact, street },
              })
            }
          />
          <Input
            type="text"
            value={office.contact.streetNumber}
            label={t("pages.office.edit.streetNumber")}
            onChange={(streetNumber) => {
              if (streetNumber)
                setOffice({
                  ...office,
                  contact: {
                    ...office.contact,
                    streetNumber: String(streetNumber),
                  },
                });
            }}
          />
          <Input
            type="text"
            value={office.contact.zip}
            label={t("pages.office.edit.zip")}
            onChange={(zip) =>
              setOffice({
                ...office,
                contact: { ...office.contact, zip },
              })
            }
          />
          <Input
            label={t("pages.office.edit.city")}
            value={office.contact.city}
            type="text"
            onChange={(city) =>
              setOffice({ ...office, contact: { ...office.contact, city } })
            }
          />
        </div>
        <div className="three-columns">
          <Dropdown
            options={generateDropdownOptions(allCompanies, "name", "id")}
            label={t("pages.office.edit.companyId")}
            onChange={(companyId) => setOffice({ ...office, companyId })}
            selectedOption={office.companyId}
          />
          <Dropdown
            options={generateDropdownOptionsForOfficeType()}
            label={t("pages.office.edit.type")}
            onChange={handleChangeOfficeType}
            selectedOption={office.officeType}
          />
          {office.officeType === OfficeType.SERVICE_POINT && (
            <Dropdown
              disabled={!office.companyId}
              options={generateDropdownOptions(loadedOffices, "name", "id")}
              onChange={(officeId) => setOffice({ ...office, officeId })}
              label={t("pages.office.edit.higherOffice")}
              selectedOption={office.officeId}
            />
          )}
        </div>
        <Dropdown
          options={generateDropdownOptions(loadedRoles, "name", "id")}
          selectedOption={office.hrRoleId}
          onChange={(hrRoleId) => setOffice({ ...office, hrRoleId })}
          label={t("pages.office.edit.hrRole")}
        />
        {office.id && isUserAllowedToDo(user.right, Right.OFFICE_DELETE) && (
          <Button
            maxWidth={250}
            value={
              office.deactivated
                ? t("pages.office.edit.activate")
                : t("pages.office.edit.deactivate")
            }
            backgroundColor={office.deactivated ? "#34b15e" : "#bc2e46"}
            onClick={() => setShowDisablePopup(true)}
            type="button"
          />
        )}
      </Box>
      <Box
        title={
          <div className="user-create__box-title-wrapper">
            <p className="box__title">
              {t("pages.office.edit.generalMailBoxTitle")}
            </p>
            <Button
              value={t("general.buttons.testConnection")}
              onClick={() =>
                sendTestMail(axios, {
                  smtpConfig: office.generalMailConfiguration,
                  receiver: user.contact.mail,
                  subject: t("pages.office.create.testMail"),
                  content: "",
                  receiverCC: [],
                  type: MailType.CONNECTION_TEST,
                })
              }
            />
          </div>
        }
      >
        <div className="three-columns">
          <Input
            type="text"
            onChange={(sendingAddress) =>
              updateMailConfig(sendingAddress, "sendingAddress", "GENERAL")
            }
            label={t("pages.office.edit.sendingAddress")}
            value={office.generalMailConfiguration?.sendingAddress || ""}
          />
          <Input
            value={office.generalMailConfiguration?.sendingName || ""}
            type="text"
            onChange={(sendingName) =>
              updateMailConfig(sendingName, "sendingName", "GENERAL")
            }
            label={t("pages.office.edit.sendingName")}
          />
          <Input
            type="text"
            onChange={(smtpServer) =>
              updateMailConfig(smtpServer, "smtpServer", "GENERAL")
            }
            value={office.generalMailConfiguration?.smtpServer || ""}
            label={t("pages.office.edit.smtpServer")}
          />
          <Input
            type="text"
            onChange={(smtpPort) =>
              setOffice({
                ...office,
                generalMailConfiguration: {
                  ...office.generalMailConfiguration,
                  smtpPort,
                },
              })
            }
            value={office.generalMailConfiguration?.smtpPort || ""}
            label={t("pages.office.edit.smtpPort")}
          />
          <Input
            type="text"
            onChange={(smtpPassword) =>
              setOffice({
                ...office,
                generalMailConfiguration: {
                  ...office.generalMailConfiguration,
                  smtpPassword,
                },
              })
            }
            value={office.generalMailConfiguration?.smtpPassword || ""}
            label={t("pages.office.edit.smtpPassword")}
          />
        </div>
      </Box>
      <Box
        title={
          <div className="user-create__box-title-wrapper">
            <p className="box__title">
              {t("pages.office.edit.accountMailBoxTitle")}
            </p>
            <Button
              value={t("general.buttons.testConnection")}
              onClick={() =>
                sendTestMail(axios, {
                  smtpConfig: office.accountingMailConfiguration,
                  receiver: user.contact.mail,
                  subject: t("pages.office.edit.testMail"),
                  content: "",
                  receiverCC: [],
                  type: MailType.CONNECTION_TEST,
                })
              }
            />
          </div>
        }
      >
        <div className="three-columns">
          <Input
            type="text"
            onChange={(sendingAddress) =>
              updateMailConfig(sendingAddress, "sendingAddress", "ACCOUNT")
            }
            label={t("pages.office.edit.sendingAddress")}
            value={office.accountingMailConfiguration?.sendingAddress || ""}
          />
          <Input
            value={office.accountingMailConfiguration?.sendingName || ""}
            type="text"
            onChange={(sendingName) =>
              updateMailConfig(sendingName, "sendingName", "ACCOUNT")
            }
            label={t("pages.office.edit.sendingName")}
          />
          <Input
            type="text"
            onChange={(smtpServer) =>
              updateMailConfig(smtpServer, "smtpServer", "ACCOUNT")
            }
            value={office.accountingMailConfiguration?.smtpServer || ""}
            label={t("pages.office.edit.smtpServer")}
          />
          <Input
            type="text"
            onChange={(smtpPort) =>
              setOffice({
                ...office,
                accountingMailConfiguration: {
                  ...office.accountingMailConfiguration,
                  smtpPort,
                },
              })
            }
            value={office.accountingMailConfiguration?.smtpPort || ""}
            label={t("pages.office.edit.smtpPort")}
          />
          <Input
            type="text"
            onChange={(smtpPassword) =>
              setOffice({
                ...office,
                accountingMailConfiguration: {
                  ...office.accountingMailConfiguration,
                  smtpPassword,
                },
              })
            }
            value={office.accountingMailConfiguration?.smtpPassword || ""}
            label={t("pages.office.edit.smtpPassword")}
          />
        </div>
      </Box>
      <Box>
        {office.receiptSignature !== undefined && (
          <Wysiwyg
            onChange={(receiptSignature) =>
              setOffice((old) => ({ ...old, receiptSignature }))
            }
            initialContent={office.receiptSignature}
            title={t("pages.office.edit.receiptSignature")}
          />
        )}
        <Input
          label={t("pages.office.edit.offerTop")}
          type="text"
          value={
            office.additionalInfos
              ? office.additionalInfos.get(AdditionalInfoType.OFFER_TOP)
              : ""
          }
          onChange={(value) => {
            const additionalInfos = office?.additionalInfos
              ? office.additionalInfos
              : new Map();
            additionalInfos.set(AdditionalInfoType.OFFER_TOP, value);
            setOffice({ ...office, additionalInfos });
          }}
        />
        <Input
          label={t("pages.office.edit.offerBottom")}
          type="text"
          value={
            office.additionalInfos
              ? office.additionalInfos.get(AdditionalInfoType.OFFER_BOTTOM)
              : ""
          }
          onChange={(value) => {
            const additionalInfos = office.additionalInfos
              ? office.additionalInfos
              : new Map();
            additionalInfos.set(AdditionalInfoType.OFFER_BOTTOM, value);
            setOffice({ ...office, additionalInfos });
          }}
        />
      </Box>
    </form>
  ) : (
    <NotAllowed neccessaryRight={Right.OFFICE_CREATE} />
  );
};
export default CreateOrEditOffice;
