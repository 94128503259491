import {
  Box,
  Button,
  Dropdown,
  Input,
  Option,
  Popup,
  Table,
  TableRow,
  TopBar,
} from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CustomerArticle,
  Order,
  ProjectCheckResult,
  ProjectProtocol,
  ProjectTimeBooking,
  createNewProjectProtocol,
  createNewSchedule,
  generateNotification,
  getCustomerArticlesForCustomer,
  getLatestUserTimeBookingForProject,
  getOrderById,
  getProtocolByDateAndProjectIdAndShift,
  getProtocolPDF,
  updateProtocol,
  useData,
} from "shared";
import { getCustomerLocationById } from "shared/src/customerLocation/CustomerLocation.axios";
import {
  CustomerLocation,
  Shift,
  ShiftType,
} from "shared/src/customerLocation/CustomerLocation.types";
import { NotificationType } from "shared/src/notification/notification.types";
import {
  getAllProjectSchedulesForProject,
  updateProjectSchedule,
} from "shared/src/project/Project.axios";
import { Project, Schedule } from "shared/src/project/Project.types";
import dayjs from "shared/src/tools/Dayjs";
import { ReactComponent as ArrowLeft } from "../../assets/chevron-left.svg";
import { ReactComponent as ArrowRight } from "../../assets/chevron-right.svg";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { useUser } from "../../components/UserContext";
import { downloadFile } from "../../utils/files/Files.utils";
import {
  convertCheckResultsToTableEntries,
  convertErrorPatternsIntoTableRows,
  convertProjectTimeBookingsIntoTableEntries,
  generateDropdownOptionsForShift,
  generateEmptyProjectCheckResult,
  generateEmptyProjectTimeBooking,
  generateEmptySchedule,
  generateErrorPatternTableHeaders,
  getNextShift,
  isResultPlausible,
  shiftIsEarlier,
} from "../../utils/project/Project.utils";
import { calculateBreakTime } from "../../utils/timeTracking/TimeTracking.utils";
import { getUserNameForSimpleUser } from "../../utils/user/User.utils";

export const ProjectProtocolCreate: React.FC = () => {
  const { axios } = useUser();
  const location = useLocation<{ project: Project }>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: loadedSimpleUsers } = useData("SIMPLEUSERS_ALL", {
    config: { fallbackData: [] },
  });
  const [originalProtocol, setOriginalProtocol] = useState<ProjectProtocol>();
  const [activeProtocol, setActiveProtocol] = useState<ProjectProtocol>();
  const [selectableShifts, setSelectableShifts] = useState<ShiftType[]>();
  const [selectedShift, setSelectedShift] = useState<ShiftType>();
  const [documentToShow, setDocumentToShow] = useState<Blob>();
  const dateNow: Date = new Date();
  dateNow.setDate(dateNow.getDate() - 1);
  const [selectedDate, setSelectedDate] = useState<Date>(dateNow);
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [plausibleRows, setPlausibleRows] = useState<string[]>([]);
  const [rowsToCheck, setRowsToCheck] = useState<string[]>([]);
  const [order, setOrder] = useState<Order>();

  const [customerArticles, setCustomerArticles] = useState<CustomerArticle[]>(
    []
  );
  const [customerLocation, setCustomerLocation] = useState<CustomerLocation>();

  //set customerArticles to select from to create resultrows
  useEffect(() => {
    if (!activeProtocol?.customerId) return;
    getCustomerArticlesForCustomer(axios, activeProtocol?.customerId).then(
      setCustomerArticles
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProtocol?.customerId]);

  // load all schedules and order for the active project
  useEffect(() => {
    if (!location.state?.project) return;
    Promise.all([
      getAllProjectSchedulesForProject(axios, location.state.project.id),
      getOrderById(axios, location.state.project.orderId),
    ]).then(([loadedSchedules, loadedOrder]) => {
      setSchedules(loadedSchedules);
      setOrder(loadedOrder);
      loadedOrder &&
        getCustomerLocationById(
          axios,
          loadedOrder.workingLocation.customerLocationId
        ).then(setCustomerLocation);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.project]);

  //the active schedule for the selected date and shift
  const selectedSchedule: Schedule | undefined = useMemo(() => {
    if (!selectedShift) return;
    return schedules.find((schedule) =>
      dayjs(selectedDate).isSame(schedule.scheduleDate, "date")
    );
  }, [schedules, selectedDate, selectedShift]);

  const selectableUsers: Option[] = useMemo(() => {
    if (!selectedShift) return [];
    const scheduledUsersSelectedDay: string[] =
      selectedSchedule?.scheduledUsers.get(selectedShift) || [];
    return (
      loadedSimpleUsers
        .filter((user) => !scheduledUsersSelectedDay.includes(user.id))
        .map((user) => ({
          label: getUserNameForSimpleUser(user.id, loadedSimpleUsers),
          value: user.id,
        })) || []
    );
  }, [loadedSimpleUsers, selectedSchedule, selectedShift]);

  /**
   * Helper method to update a booking of the active Protocol
   * @param updatedBooking upated booking to replace the existing one
   */
  const updateTimeBookings = (
    updatedBooking: ProjectTimeBooking,
    remove?: boolean
  ): void => {
    setActiveProtocol((prev) => {
      if (!prev) return;
      return remove
        ? {
            ...prev,
            timeBookings: prev.timeBookings.filter(
              (booking) => booking.userId !== updatedBooking.userId
            ),
          }
        : {
            ...prev,
            timeBookings: prev.timeBookings.map((booking) =>
              booking.userId === updatedBooking.userId
                ? updatedBooking
                : booking
            ),
          };
    });
  };

  /**
   * Helper method to update the checkResults for the project
   * @param updatedResult updated result to save
   */
  const updateCheckResults = (updatedResult: ProjectCheckResult): void => {
    setActiveProtocol((prev) => {
      if (!prev) return;
      return {
        ...prev,
        checkResults: prev.checkResults.map((result) =>
          result.id === updatedResult.id ? updatedResult : result
        ),
      };
    });
  };

  // Hook to keep userRows up to date
  const userRows: TableRow[] = useMemo((): TableRow[] => {
    if (!activeProtocol) return [];
    return convertProjectTimeBookingsIntoTableEntries(
      activeProtocol.timeBookings,
      loadedSimpleUsers,
      updateTimeBookings
    );
  }, [activeProtocol, loadedSimpleUsers]);

  //Hook to keep rows for the existing errorPatterns for the project up to date
  const patternRows: TableRow[] = useMemo(() => {
    if (!location.state?.project?.errorPatterns) return [];
    return convertErrorPatternsIntoTableRows(
      location.state.project.errorPatterns,
      undefined,
      true
    );
  }, [location.state?.project?.errorPatterns]);

  //useCallback function to check if a checkReult is plausible or not
  const checkPlausibleResult = useCallback(
    (resultId: string): void => {
      if (!activeProtocol) return;
      const result: ProjectCheckResult | undefined =
        activeProtocol.checkResults.find(
          (resultToCheck) => resultToCheck.id === resultId
        );
      if (result) {
        const isPlausible: boolean = isResultPlausible(result);
        if (isPlausible) {
          if (!rowsToCheck.includes(resultId))
            setPlausibleRows((prev) => [...prev, resultId]);
        } else {
          setPlausibleRows((prev) => prev.filter((row) => row !== resultId));
        }
      }
    },
    [activeProtocol, rowsToCheck]
  );

  //Hook to keep rows for the results up to date
  const resultRows: TableRow[] = useMemo((): TableRow[] => {
    if (!activeProtocol) return [];
    return convertCheckResultsToTableEntries(
      activeProtocol.checkResults,
      updateCheckResults,
      location.state?.project?.errorPatterns || [],
      customerArticles,
      checkPlausibleResult
    );
  }, [
    activeProtocol,
    location.state?.project?.errorPatterns,
    customerArticles,
    checkPlausibleResult,
  ]);

  //Check the results after each selection of a different protocol and initially set plausibility
  useEffect(() => {
    if (!activeProtocol) return;
    activeProtocol.checkResults.forEach((result) =>
      checkPlausibleResult(result.id)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProtocol?.id, checkPlausibleResult]);

  //Hook to get the correct protocol after each change of the date or shift
  useEffect(() => {
    if (!location.state?.project?.id) return;
    if (!selectedShift)
      getOrderById(axios, location.state.project.orderId).then(
        (loadedOrder) => {
          if (!loadedOrder) return;
          const orderShifts: ShiftType[] = loadedOrder?.shifts.map(
            (shift) => shift.type
          );
          setSelectableShifts(orderShifts);
          setSelectedShift(orderShifts[0]);
        }
      );
    else
      getProtocolByDateAndProjectIdAndShift(
        axios,
        location.state.project.id,
        selectedDate,
        selectedShift
      ).then((loadedProtocol) => {
        setActiveProtocol(loadedProtocol);
        setOriginalProtocol(loadedProtocol);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.project, selectedDate, selectedShift]);

  /**
   * Submit handler that starts the update on the database
   * @param evt FormEvent to prevent default behaviour
   */
  const handleSubmit = (evt: FormEvent): void => {
    evt.preventDefault();
    if (!activeProtocol) return;
    if (selectedSchedule)
      selectedSchedule.id
        ? updateProjectSchedule(axios, selectedSchedule)
        : createNewSchedule(axios, selectedSchedule);
    updateProtocol(axios, activeProtocol).then(
      (success) => success && navigate(-1)
    );
  };

  //Helper metohd to add a new projectCheckResult
  const handleAddCheckResult = (): void => {
    const generatedCheckResult = generateEmptyProjectCheckResult();
    if (activeProtocol)
      setActiveProtocol({
        ...activeProtocol,
        checkResults: [...activeProtocol.checkResults, generatedCheckResult],
      });
    setRowsToCheck([...plausibleRows, generatedCheckResult.id]);
  };

  /**
   * Helper method to create a new Schedule and Protocol
   * @param userId id of the user to add to the created protocol
   */
  const createScheduleAndProtocol = (userId: string): void => {
    if (!selectedShift || !location.state?.project) return;
    const scheduledUser: Map<ShiftType, string[]> = new Map<
      ShiftType,
      string[]
    >();
    scheduledUser.set(selectedShift, [userId]);
    const createdSchedule: Schedule = generateEmptySchedule({
      orderId: location.state.project.orderId,
      referenceId: location.state.project.id,
      scheduledUsers: scheduledUser,
      scheduleDate: selectedDate,
    });
    if (createdSchedule && location.state?.project) {
      createNewProjectProtocol(
        axios,
        selectedShift,
        location.state.project.id,
        selectedDate,
        createdSchedule
      ).then((createdProtocol) => {
        if (!createdProtocol) return;
        const activeShift: Shift | undefined = order?.shifts?.find(
          (shift) => shift.type === selectedShift
        );
        const editedProtocol: ProjectProtocol = {
          ...createdProtocol,
          timeBookings: [
            generateEmptyProjectTimeBooking({
              userId,
              endTime: activeShift?.endTime,
              startTime: activeShift?.startTime,
              breakTime: activeShift
                ? calculateBreakTime(
                    activeShift?.startTime,
                    activeShift?.endTime
                  )
                : 0,
            }),
          ],
        };
        setActiveProtocol(editedProtocol);
        setOriginalProtocol(editedProtocol);
        setSchedules([...schedules, createdSchedule]);
      });
    }
  };

  /**
   * Util method to add a timeBooking to a project and schedule
   * @param userId id of the user to add
   */
  const addTimeBookingToProject = (userId: string): void => {
    if (!selectedShift || !activeProtocol || !selectedSchedule) return;
    const scheduleToUpdate: Map<ShiftType, string[]> | undefined =
      selectedSchedule?.scheduledUsers;
    const updatedUsers: string[] = [
      ...(scheduleToUpdate?.get(selectedShift) || []),
      userId,
    ];
    if (!scheduleToUpdate) return;
    scheduleToUpdate.set(selectedShift, updatedUsers);
    setSchedules(
      schedules.map((schedule) =>
        schedule.id === selectedSchedule.id
          ? { ...schedule, scheduledUsers: scheduleToUpdate }
          : schedule
      )
    );

    let newBooking: ProjectTimeBooking = generateEmptyProjectTimeBooking({
      userId,
      startTime: selectedSchedule.appointmentDetail?.startTime,
      endTime: selectedSchedule.appointmentDetail?.endTime,
      breakTime: calculateBreakTime(
        selectedSchedule.appointmentDetail?.startTime || 0,
        selectedSchedule.appointmentDetail?.endTime
      ),
    });
    const activeShift: Shift | undefined = order?.shifts?.find(
      (shift) => shift.type === selectedShift
    );
    const drivingTime: number =
      customerLocation?.officeDrivingTimes.get(activeProtocol.officeId) || 0;
    newBooking.customerDrivingTime = drivingTime;
    if (
      activeShift &&
      selectedSchedule.scheduledCars.get(activeShift.type) &&
      selectedSchedule.scheduledCars.get(activeShift.type)!.length >=
        activeProtocol.timeBookings.length
    ) {
      newBooking.startTime = newBooking.startTime - drivingTime / 2;
      newBooking.endTime = newBooking.endTime + drivingTime / 2;
    }
    getLatestUserTimeBookingForProject(
      axios,
      activeProtocol.projectId,
      userId
    ).then((loadedTimeBooking) => {
      if (!loadedTimeBooking || !activeShift) return;
      newBooking = {
        ...newBooking,
        customerArrival: loadedTimeBooking.customerArrival,
        customerDeparture: loadedTimeBooking.customerArrival,
        customerDrivingTime: loadedTimeBooking.customerDrivingTime,
        userArrival: loadedTimeBooking.userArrival,
        userDeparture: loadedTimeBooking.userDeparture,
        startTime: loadedTimeBooking.customerArrival
          ? activeShift.startTime - drivingTime / 2
          : activeShift.startTime,
        endTime: loadedTimeBooking.customerDeparture
          ? activeShift.endTime + drivingTime / 2
          : activeShift.endTime,
      };
    });
    setActiveProtocol({
      ...activeProtocol,
      timeBookings: [...activeProtocol.timeBookings, newBooking],
    });
  };

  /**
   * Helper method to add a user to the active schedule and adds an empty timeBookings for display
   * @param userId id of the user to add
   */
  const addUserToSchedule = (userId: string): void => {
    if (!selectedShift || !location.state?.project) return;
    if (!selectedSchedule || !activeProtocol) {
      createScheduleAndProtocol(userId);
    } else {
      addTimeBookingToProject(userId);
    }
  };

  /**
   * Helper method to get the protocol document and open it in a popup
   */
  const openDocumentPreview = (): void => {
    if (!location.state?.project) return;
    getProtocolPDF(axios, location.state.project.id).then(setDocumentToShow);
  };

  /**
   * Helper method to download the document as pdf
   */
  const downloadProtocol = async (): Promise<void> => {
    if (!location.state?.project) return;
    const document: Blob | undefined = await getProtocolPDF(
      axios,
      location.state.project.id
    );
    if (document)
      downloadFile(
        document,
        t("pages.project.protocol.fileName", {
          replace: { number: location.state.project.numberRangeNumber || "" },
        })
      );
  };

  /**
   * Helper method to update the times or results without updating the whole object, without loosing local changes
   * @param type decides if the times or the results should be updated
   */
  const updateProjectPart = (type: "times" | "results"): void => {
    if (!activeProtocol || !originalProtocol)
      return generateNotification({
        type: NotificationType.ERROR,
        value: t("general.notification.error.updateProtocol"),
      });

    //Keep schedules in sync with timeBookings, so we update or create an schedule if we update the timeBookings
    if (selectedSchedule && type === "times")
      selectedSchedule.id
        ? updateProjectSchedule(axios, selectedSchedule)
        : createNewSchedule(axios, selectedSchedule);

    const editedProtocol: ProjectProtocol =
      type === "times"
        ? { ...activeProtocol, checkResults: originalProtocol.checkResults }
        : { ...activeProtocol, timeBookings: originalProtocol.timeBookings };

    updateProtocol(axios, editedProtocol).then((updatedProtocol) => {
      if (updatedProtocol) {
        setActiveProtocol(
          type === "times"
            ? { ...updatedProtocol, checkResults: activeProtocol.checkResults }
            : { ...updatedProtocol, timeBookings: activeProtocol.timeBookings }
        );
        setOriginalProtocol(updatedProtocol);

        generateNotification({
          type: NotificationType.SUCCESS,
          value:
            type === "times"
              ? t("general.notification.success.updateProtocolTimes")
              : t("general.notification.success.updateProtocolResults"),
        });
      }
    });
  };

  /**
   * Method to handle click on the chevron to get to the next day or shift
   */
  const handleNextClick = (): void => {
    if (!selectedShift || !selectableShifts) return;

    const nextShift: ShiftType = getNextShift(
      selectedShift,
      selectableShifts,
      true
    );
    if (
      selectableShifts?.length === 1 ||
      shiftIsEarlier(selectedShift, nextShift)
    ) {
      const nextDate = new Date(selectedDate);
      nextDate.setDate(nextDate.getDate() + 1);
      setSelectedDate(nextDate);
    }
    setSelectedShift(nextShift);
  };
  /**
   *
   * Util method to handle click to the previous shift
   * @returns
   */
  const handlePrevClick = (): void => {
    if (!selectedShift || !selectableShifts) return;
    const prevShift: ShiftType = getNextShift(
      selectedShift,
      selectableShifts,
      true
    );
    if (
      selectableShifts.length === 1 ||
      !shiftIsEarlier(prevShift, selectedShift)
    ) {
      const nextDate = new Date(selectedDate);
      nextDate.setDate(nextDate.getDate() - 1);
      setSelectedDate(nextDate);
    }
    setSelectedShift(prevShift);
  };
  return (
    <form onSubmit={handleSubmit}>
      <TopBar
        title={t("pages.project.protocol.topBarHeadline")}
        onBackClick={() => navigate(-1)}
      >
        <Button
          value={t("general.buttons.documentPreview")}
          onClick={openDocumentPreview}
        />
        <Button value={t("general.buttons.save")} type="submit" />
        <Button
          value={t("general.buttons.download")}
          type="button"
          onClick={downloadProtocol}
        />
      </TopBar>
      {documentToShow && (
        <Popup
          isOpen={!!documentToShow}
          onClose={() => setDocumentToShow(undefined)}
        >
          <object
            data={URL.createObjectURL(documentToShow)}
            type={documentToShow.type}
            width="1000px"
            height="700px"
          />
        </Popup>
      )}
      <div className="project-protocol__input-wrapper">
        <div className="project-protocol__input-wrapper__date-wrapper">
          <ArrowLeft width={25} onClick={handlePrevClick} />
          <Input
            maxWidth={300}
            label={t("pages.project.protocol.date")}
            value={selectedDate}
            type="date"
            onChangeDate={(date) => date && setSelectedDate(date)}
          />
          <ArrowRight width={25} onClick={handleNextClick} />
        </div>
        <Dropdown
          onChange={(shift) => setSelectedShift(shift as ShiftType)}
          label={t("pages.project.protocol.shift")}
          options={generateDropdownOptionsForShift(selectableShifts)}
          selectedOption={selectedShift}
        />
      </div>
      <Box
        title={
          <div className="user-create__box-title-wrapper">
            <p className="box__title">
              {t("pages.project.protocol.timetable")}
            </p>
            <div className="project-protocol__dropdown-wrapper">
              <Dropdown
                label={t("pages.project.protocol.selectUser")}
                options={selectableUsers}
                onChange={(userId) => addUserToSchedule(userId)}
              />
              <Button
                value={t("general.buttons.save")}
                onClick={() => updateProjectPart("times")}
              />
            </div>
          </div>
        }
      >
        <Table
          rows={userRows}
          header={
            t("pages.project.protocol.timesTableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
      <Box title={t("pages.project.protocol.errorPatterns")}>
        <Table
          rows={patternRows}
          header={
            t("pages.project.protocol.patternHeaders", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
      <Box
        title={
          <div className="user-create__box-title-wrapper">
            <p className="box__title">
              {t("pages.project.protocol.checkResults")}
            </p>
            <div className="project-protocol__dropdown-wrapper">
              <PlusIcon
                onClick={handleAddCheckResult}
                className="user-create__box-title-wrapper__icon"
              />
              <Button
                value={t("general.buttons.save")}
                onClick={() => updateProjectPart("results")}
              />
            </div>
          </div>
        }
      >
        <Table
          rows={resultRows.map((row) =>
            plausibleRows.includes(row.id)
              ? row
              : { ...row, background: "yellow" }
          )}
          header={generateErrorPatternTableHeaders(
            location.state?.project?.errorPatterns || []
          )}
        />
      </Box>
    </form>
  );
};
