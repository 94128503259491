import { Option, TableRow } from "@sam/components";
import { AxiosInstance } from "axios";
import React, { Dispatch } from "react";
import {
  Country,
  County,
  DayOfWeek,
  SchambeckUser,
  TimeTracking,
  WorkSchedule,
  WorkScheduleDistribution,
  endAndSaveTimeTracking,
  generateNotification,
  getTimeTrackingForUserAndDay,
} from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import { Right } from "shared/src/userRole/UserRole.types";
import { uid } from "uid";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import i18n from "../../i18n/i18n";
import { db } from "../indexedDB/db";
import {
  addTimeTrackingIndexedDB,
  convertIndexedTimeTracking,
  countDbTimeTrackingEntries,
  getIndexedTimeTracking,
} from "../indexedDB/db.utils";
import { isUserAllowedToDo } from "../user/User.utils";

/**
 * Helper to generate empty Timetracking object with start now
 * @param userId of the user to create timeTracking for
 * @returns created TimeTracking object
 */
export const generateEmptyTimeTracking = (userId: string): TimeTracking => {
  const dateNow = new Date();
  return {
    id: undefined!,
    createDate: dateNow,
    userId,
    startTime: getMinutesOfDay(dateNow),
    breakTime: 0,
    comment: "",
    drivingTime: 0,
    endTime: 0,
    startDate: dateNow,
    holidayTime: 0,
    nightTime: 0,
    saturdayTime: 0,
    sundayTime: 0,
    totalWorkTime: 0,
  };
};

/**
 * Helper method to get minutes of a day from 0:00 until now
 * @param day with time to check
 * If no day is provided, the actual date from now is used
 * @returns number of minutes
 */
export const getMinutesOfDay = (day?: Date): number => {
  const dayToCount: Date = day || new Date();
  return dayToCount.getHours() * 60 + dayToCount.getMinutes();
};

/**
 * Helper to calculate worktime from start to now
 * @param startTime as minutes of the day
 * @param endTime time to end calculating, default value is now
 * @returns array with hours and minutes
 */
export const calculateWorkingTime = (
  startTime: number,
  endTime: number = getMinutesOfDay()
): [hours: number, minutes: number] => {
  let hours: number = 0;
  let minutes: number = 0;
  //normal calculation
  if (startTime < endTime) {
    hours = Math.floor((endTime - startTime) / 60);
    minutes = (endTime - startTime) % 60;
  } else {
    //working overnight
    const totalMinutes: number = 24 * 60 - startTime + endTime;
    hours = Math.floor(totalMinutes / 60);
    minutes = totalMinutes % 60;
  }
  return [hours, minutes];
};

/**
 * Helper method to calculate breakTime from start till now
 * The times are 30m between 6 and 9 hours and 45m above 9 hours
 * @param startTime to calculate from
 * @returns  number of minutes of breakTime
 */
export const calculateBreakTime = (
  startTime: number,
  endTime?: number
): number => {
  const [hours] = calculateWorkingTime(startTime, endTime);
  return hours >= 6 ? (hours >= 9 ? 45 : 30) : 0;
};

/**
 * Helper method to load timeTracking from indexedDB if possible, then chekc mongoDB if nothing was found
 * @param axios instance of axios
 * @param userId id of the user to load the timeTracking for
 * @returns found TimeTracking or undefined
 */
export const getTimeTrackingFromIndexedOrMongoDB = async (
  axios: AxiosInstance,
  userId: string
): Promise<TimeTracking | undefined> => {
  return getIndexedTimeTracking().then(async (loadedTracking) => {
    if (loadedTracking !== undefined)
      return convertIndexedTimeTracking(loadedTracking, userId);
    else {
      return await getTimeTrackingForUserAndDay(axios, userId).then(
        async (foundTracking) => {
          const dbEntryAmount: number = await countDbTimeTrackingEntries().then(
            (count) => count
          );
          //If endTime is 0, the tracking is not completed yet. If it is not 0, the tracking is completed
          if (foundTracking?.endTime === 0 && dbEntryAmount === 0)
            addTimeTrackingIndexedDB(foundTracking);
          return foundTracking;
        }
      );
    }
  });
};

/**
 * Util to generate workschedules which distribute the given working time
 * evenly from MO to FR.
 *
 * @param weeklyWorkingTime The user enetered total weekly working time
 * @returns The generated work schedules
 * @tested
 */
export const calculateDailyWorkTimeForWeeklySchedule = (
  weeklyWorkingTime: number
): WorkSchedule[] => {
  if (weeklyWorkingTime <= 0) return [];
  const dailyWorkTime: number = weeklyWorkingTime / 5;
  return Object.values(DayOfWeek).map((day) => {
    return {
      id: uid(),
      dayOfWeek: day,
      workingHours: day === "SATURDAY" || day === "SUNDAY" ? 0 : dailyWorkTime,
    };
  });
};

/**
 * Util to generate WorkSchedules based on the given total weekly working
 * time and the selected days. The total weekly time is evenly divided on
 * the given days.
 *
 * @param weeklyWorkingTime The user entered total weekly working time
 * @param selectedDays The user selected list of selected days
 * @returns The generated work schedules
 * @tested
 */
export const calculateDailyWorkForGivenDays = (
  weeklyWorkingTime: number,
  selectedDays: DayOfWeek[]
): WorkSchedule[] => {
  if (selectedDays.length === 0 || weeklyWorkingTime <= 0) return [];
  const daysToWork: number = selectedDays.length;
  const dailyWorkTime: number = weeklyWorkingTime / daysToWork;
  return Object.values(DayOfWeek).map((day) => {
    return {
      id: uid(),
      dayOfWeek: day,
      workingHours: selectedDays.includes(day) ? dailyWorkTime : 0,
    };
  });
};

/**
 * Util to generate work schedules based on precisely entered daily working hours.
 *
 * @param preciseEntries The user generated precise entries
 * @returns The generated work schedules
 * @tested
 */
export const calculatePreciseWorkScheduleForUser = (
  preciseEntries: Map<DayOfWeek, number>
): WorkSchedule[] => {
  if (preciseEntries.size <= 0) return [];
  return Object.values(DayOfWeek).map((day) => {
    return {
      id: uid(),
      dayOfWeek: day,
      workingHours: Math.max(preciseEntries.get(day) || 0, 0),
    };
  });
};

/**
 * Helper to map the given user working schedule on the needed Map for the render component
 *
 * @param user The user to create the mapping for
 * @returns Mapping of the precise working schedule
 * @tested
 */
export const generatePreciseMappingFromSchedule = (
  user: SchambeckUser
): Map<DayOfWeek, number> => {
  const returnMap: Map<DayOfWeek, number> = new Map<DayOfWeek, number>();
  if (
    user.contractInformation?.workScheduleDistribution ===
    WorkScheduleDistribution.PRECISE
  ) {
    user.contractInformation.workSchedules.forEach((schedule) => {
      if (schedule.workingHours <= 0) return;
      returnMap.set(schedule.dayOfWeek, schedule.workingHours);
    });
  }
  return returnMap;
};

/**
 * Helper to calculate the total weekly working time in case the user has a
 * precise schedule mapping. In other cases the already assigned weekly time
 * is returned.
 *
 * @param user The user to calculate the weekly time for
 * @returns The amount of total weekly working time
 * @tested
 */
export const getTotalWorkingTimeFromPreciseMapping = (
  user: SchambeckUser,
  schedules: WorkSchedule[]
): number => {
  if (
    user.contractInformation?.workScheduleDistribution !==
    WorkScheduleDistribution.PRECISE
  )
    return user.contractInformation?.weeklyWorkingTime || 0;
  return schedules.reduce((sum, schedule) => sum + schedule.workingHours, 0);
};

/**
 * Helper method to convert number of minutes from 00:00 to time
 * @param minutes to convert
 * @param withoutSpaces defines the format of the answerString string
 * @returns string in the format hh : mm or hh:mm with the correct time
 */
export const convertNumberOfMinutesToTime = (
  minutes: number,
  withoutSpaces: boolean = false
): string => {
  const fullHoursinMinutes: number = Math.floor(minutes / 60);
  const hourString: string = `${fullHoursinMinutes}`.padStart(2, "0");

  const minutesWithoutHours: number = minutes % 60;
  const minutesString: string = `${minutesWithoutHours}`.padStart(2, "0");
  return `${hourString}${withoutSpaces ? ":" : " : "}${minutesString}`;
};

/**
 * Helper method to convert number of minutes to time in the format hh"h" mm"m"
 * @param minutes to convert
 * @returns string in the format hh:mm with correct time
 */
export const convertNumberOfMinutesToString = (minutes: number): string => {
  const fullHoursinMinutes: number = Math.floor(minutes / 60);
  const minutesWithoutHours: number = minutes % 60;

  return fullHoursinMinutes === 0
    ? `${minutesWithoutHours}m`
    : `${fullHoursinMinutes}h ${
        minutesWithoutHours > 0 ? minutesWithoutHours : minutesWithoutHours * -1
      }m`;
};

/**
 * Helper to transform given holidays to Tablerows
 * @param timeTrackings to create rows from
 * @param navigate to create Icons
 * @returns Tablerows
 */
export const convertTimeTrackingsIntoTableEntries = (
  user: SchambeckUser,
  timeTrackings: TimeTracking[],
  setTrackingToEdit: Dispatch<React.SetStateAction<TimeTracking | undefined>>,
  setTrackingToDelete?: Dispatch<string | undefined>
): TableRow[] => {
  return timeTrackings.map((tracking) => ({
    id: tracking.id,
    content: [
      tracking.startDate.toLocaleDateString("de-DE"),
      `${convertNumberOfMinutesToTime(tracking.startTime)}  ${i18n.t(
        "pages.timekeeping.clock"
      )}`,
      `${convertNumberOfMinutesToTime(tracking.endTime)}  ${i18n.t(
        "pages.timekeeping.clock"
      )}`,
      convertNumberOfMinutesToString(tracking.breakTime),
      convertNumberOfMinutesToString(tracking.totalWorkTime),
      tracking.comment,
      <div>
        {(isUserAllowedToDo(user.right, Right.TIME_EDIT) ||
          user.id === tracking.userId) && (
          <div className="table-action__icon-wrapper">
            <EditIcon width={20} onClick={() => setTrackingToEdit(tracking)} />
            {setTrackingToDelete && (
              <DeleteIcon
                className="delete"
                width={20}
                onClick={() => setTrackingToDelete(tracking.id)}
              />
            )}
          </div>
        )}
      </div>,
    ],
  }));
};

/**
 * Helper method to create options for the dropdown to choose a year
 * @returns array of options with label and value
 */
export const generateDropdownOptionsForYear = (): Option[] => {
  const yearNow: number = new Date().getFullYear();
  const options: Option[] = [];
  for (let i = yearNow - 10; i <= yearNow + 1; i++) {
    options.push({ label: i, value: i.toString() });
  }
  options.sort((a, b) => (Number(a.value) >= Number(b.value) ? -1 : 1));
  return options;
};
/**
 * Helper method to create options for the dropdown to choose a month
 * @returns array of options with label and value
 */
export const generateDropdownOptionsForMonth = (): Option[] => {
  const options: Option[] = [];
  for (let curMonth = 0; curMonth <= 11; curMonth++) {
    const optionDate: Date = new Date();
    optionDate.setMonth(curMonth);
    options.push({
      label: i18n.t(`general.month.${curMonth}`),
      value: curMonth.toString(),
    });
  }

  return options;
};

/**
 * Util method to generate dropdown options for countries
 * @returns array of dropdown options
 */
export const generateDropdownOptionsForCountry = (): Option[] => {
  return Object.values(Country).map((country) => ({
    label: i18n.t(`general.country.${country}`),
    value: country,
  }));
};

/**
 * Util method to generate dropdown options for counties
 * @returns array of dropdown options
 */
export const generateDropdownOptionsForCounty = (): Option[] => {
  return Object.values(County)
    .filter((county) => county !== County.OTHER)
    .map((county) => ({
      label: i18n.t(`general.county.${county}`),
      value: county,
    }));
};

/**
 * Util method to convert a time string from the format hh : mm or hh:mm to the number of minutes from 00:00.
 * @param timeString to convert
 * @returns number of total minutes
 */
export const getMinutesFromTimeString = (timeString: string): number => {
  const times: string[] = timeString.split(":");
  const timesWithoutSpaces: string[] = [
    times[0].replace(/\s/g, ""),
    times[1].replace(/\s/g, ""),
  ];
  const numberOfMinutes: number =
    Number(timesWithoutSpaces[0]) * 60 + Number(timesWithoutSpaces[1]);
  return numberOfMinutes;
};

/**
 * Helper method to get the correct display text to display for the actual timeTracking of the day
 * depending on the activeTracking startTime, endTime and totalWorkTime
 * @returns string to display
 */
export const getCorrectDisplayText = (
  activeTracking?: TimeTracking
): string => {
  if (!activeTracking) return i18n.t("pages.timekeeping.noActiveTracking");
  else if (activeTracking.endTime === 0)
    return `${i18n.t(
      "pages.timekeeping.start"
    )}: ${convertNumberOfMinutesToTime(activeTracking.startTime)} ${i18n.t(
      "pages.timekeeping.clock"
    )}`;
  else
    return `${i18n.t(
      "pages.timekeeping.trackedTime"
    )}: ${convertNumberOfMinutesToTime(activeTracking.totalWorkTime)}`;
};

/**
 * Util method to end a timeTracking with the actual time, that updates the tracking on the database and clears the indexedDB
 * @param userId id of the user the timetracking is for
 * @param activeTracking tracking to update and end
 * @param axios instance of axios
 * @returns updated tracking or undefined on error
 */
export const endTimeTracking = async (
  userId: string,
  activeTracking: TimeTracking,
  axios: AxiosInstance
): Promise<TimeTracking | undefined> => {
  const workTimeNow: number[] = calculateWorkingTime(activeTracking.startTime);
  const today: Date = new Date();
  const updatedTracking: TimeTracking = {
    ...activeTracking,
    endDate: today,
    userId: userId,
    endTime: getMinutesOfDay(today),
    totalWorkTime:
      workTimeNow[0] * 60 + workTimeNow[1] - activeTracking.breakTime,
  };

  if (updatedTracking.totalWorkTime <= 0) {
    generateNotification({
      type: NotificationType.WARNING,
      value: i18n.t("general.notification.error.timeTrackingTimes"),
    });
    return;
  }
  await endAndSaveTimeTracking(axios, updatedTracking);
  await db.timeTracking.clear();
  return updatedTracking;
};
