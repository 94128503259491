import { AxiosInstance } from "axios";
import { Receipt } from "../invoice/Invoice.types";
import { generateNotification } from "../notification/notification.util";
import { NotificationType } from "../notification/notification.types";
import i18n from "../../../client/src/i18n/i18n";

/**
 * API Method to create a new creditNote
 * @param axios instance of axios
 * @param receipt new Invoice that is used to calculate the creditNote !This invoice does not get persistet on the db, it is only to calculate a credit note, which is saved!
 * @returns Promise containing the receipt with type credit Note
 */
export const createNewCreditNoteReceipt = async (
  axios: AxiosInstance,
  receipt: Receipt
): Promise<Receipt | undefined> => {
  return axios
    .post("/receipt/credit", receipt)
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while creating new receipt", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createCreditNote"),
      });
    });
};

/**
 * API method to create a new cancel receipt, which is a copy of the originalInvoice with negative values and a new invoice
 * @param axios instance of axios
 * @param receipt new Invoice, the is created after the old invoice was cancelled
 * @returns Promise containing the cancelReceipt
 */
export const createNewCancelReceipt = async (
  axios: AxiosInstance,
  receipt: Receipt
): Promise<Receipt | undefined> => {
  return axios
    .post("/receipt/cancel", receipt)
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while creating new receipt", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createCancelReceipt"),
      });
    });
};
