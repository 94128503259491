import { Box, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Receipt, InvoiceState, getAllInvoicesByStates, useData } from "shared";
import { useUser } from "../../components/UserContext";
import { convertInvoicesIntoAccountingTableEntries } from "../../utils/invoice/Invoice.utils";

export const AccountingOverview: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { axios } = useUser();
  const [invoices, setInvoices] = useState<Receipt[]>([]);
  const [filterValue, setFilterValue] = useState<string>("");

  const { data: loadedOrders } = useData("ORDER_ALL", {
    config: { fallbackData: [] },
  });

  const { data: loadedCustomers } = useData("CUSTOMER_ALL", {
    config: { fallbackData: [] },
  });

  const { data: loadedOffices } = useData("OFFICES_ALL", {
    config: { fallbackData: [] },
  });
  /**
   * useEffect to load all invoices in the state PENDING
   */
  useEffect(() => {
    getAllInvoicesByStates(axios, [InvoiceState.PENDING]).then(
      (loadedInvoices) => setInvoices(loadedInvoices)
    );
  }, [axios]);

  /**
   * Contains the tableRows with the converted invoices
   */
  const invoiceRows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertInvoicesIntoAccountingTableEntries(
        invoices,
        loadedOrders,
        loadedCustomers,
        loadedOffices,
        navigate
      ),
    [invoices, loadedOrders, loadedCustomers, loadedOffices, navigate]
  );

  return (
    <>
      <TopBar
        title={t("pages.accounting.overview.topBarHeadline")}
        onSearch={setFilterValue}
      />
      <Box>
        <Table
          filterValue={filterValue}
          rows={invoiceRows}
          header={
            t("pages.accounting.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
